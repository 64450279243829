import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Nav, Navbar, Modal } from 'react-bootstrap';
import { NavLink, useNavigate, Navigate } from 'react-router-dom';
import { Buttons, ClickButton } from '../components/Buttons';
import { ProductTable, Tables, TableUI } from '../components/Tables'
import Pagnation from '../components/Pagnation';
import { TextInputForm, DropDownUI } from '../components/Forms';
import { PageHead, PageTitle } from '../components/PageTitle'
import { FaMagnifyingGlass } from "react-icons/fa6";
import '../components/Forms.css'
import PropagateLoader from "react-spinners/PropagateLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
const UserTablehead = ["S.No", "CompanyName", "GST Number", "MobileNumber", "Action"]
// const ProductHisData = [
//     { values: ["1", "KANNAN", "AS25AS5454", "9865323265",] },
//     { values: ["2", "PRABA", "WE8554WEWE", "s9865323265",] },]
// const ProductHisData = [
//     {
//         "id": "1",
//         "company_name": 'PRABAU',
//         "gst_no": "WE8554WEWE",
//         "mobile_number": '9876355677',
//     }
// ]
const Company = () => {
    const location = useLocation();
    const { type, rowData } = location.state || {};
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setCrtCompany(null)
        setShow(true);
    }
    const [crtCompany, setCrtCompany] = useState(null)
    const handleEditClick = (rowData) => {
        setCrtCompany(rowData)
        setShow(true)
    }
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        company_name: '',
        mobile_number: '',
        gst_no: '',
        Address: '',
        Billing_Address: '',
        Shipping_Addres: '',
        fssai: '',
        bill_prefix: '',
        bank_name: '',
        acc_no: '',
        ifsc_code: '',
        upi_no: '',
    });
    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;

        setCrtCompany({
            ...crtCompany,
            [fieldName]: value
        });
    };


    const [userData, setUserData] = useState([]);
    console.log('userData', userData);
    const fetchData = async () => {
        try {
            const response = await fetch('https://node.zentexus.com/company/list/joyg67j8t689t', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: ''
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            setLoading(false);
            if (responseData.status === 200) {
                setUserData(responseData.data);
                console.log(responseData)
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };
    useEffect(() => {
        fetchData(); // Call fetchData directly in useEffect
    }, []);
    const [loading, setLoading] = useState(true);
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setLoading(false);
    //     }, 2000);
    //     return () => clearTimeout(timer);
    // }, []);


    const [showAlert, setShowAlert] = useState(false);
    const handleUpdateSubmit = async () => {
        try {
            const response = await fetch('https://node.zentexus.com/company/update/joyg67j8t689t', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ // Include the company ID in the request
                    company_id: crtCompany.company_id,
                    company_name: crtCompany.company_name,
                    mobile_number: crtCompany.mobile_number,
                    gst_no: crtCompany.gst_no,
                    fssai_code: crtCompany.fssai_code,
                    address: crtCompany.address,
                    city: crtCompany.city,
                    state: crtCompany.state,
                    bill_prefix: crtCompany.bill_prefix,
                    bank: crtCompany.bank,
                    bank_name: crtCompany.bank_name,
                    acc_no: crtCompany.acc_no,
                    ifsc_code: crtCompany.ifsc_code,
                    upi_no: crtCompany.upi_no,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update company');
            }

            const responseData = await response.json();
            console.log(responseData);

            if (responseData.status === 200) {
                fetchData();
                toast.success('Company updated successfully!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    handleClose();
                }, 2000);

                // Navigate to the user list page after a delay

            } else {
                console.error(responseData.msg || 'Unknown error occurred during update');
            }
        } catch (error) {
            console.error('Error updating product:', error.msg);
        }

        setLoading(false);
    };

    const [isLoggedIn, setIsLoggedIn] = useState(true);
    useEffect(() => {
        const checkSession = async () => {
            try {
                // Check session logic here, e.g., fetch from local storage or server
                const session = localStorage.getItem('session');
                if (!session) {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Error checking session:', error.message);
                setIsLoggedIn(false);
            }
        };
        checkSession();
    }, []);
    if (!isLoggedIn) {

        return <Navigate to="/login" replace />;
    }
    return (
        <div>
            <Container fluid>

                <div className='main my-3'>
                    <Row>
                        <Col lg='12' md='12' xs='12' className='align-self-center'>
                            <PageTitle PageTitle={"Company"} />
                        </Col>
                        <Col lg={12} md={12} xs={12} className='py-2'>
                            <div className='py-2 c-table'>
                                <TableUI headers={UserTablehead} body={userData} handleEditClick={handleEditClick} type="company" />
                            </div>
                        </Col>
                    </Row>
                </div>

            </Container>
            <Modal show={show} onHide={handleClose} size='xl' centered scrollable>
                <Modal.Header>
                    <Modal.Title>
                        <PageHead pagehead={<>Company Creation</>} onClick={handleClose} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'Company Name'}
                                    labelname={'Company Name'}
                                    name='company_name'
                                    value={crtCompany != null ? crtCompany.company_name : ''}
                                    onChange={(e) => handleChange(e, 'company_name')}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'MobileNumber'}
                                    labelname={'MobileNumber'}
                                    name='mobile_number'
                                    value={crtCompany != null ? crtCompany.mobile_number : ''}
                                    onChange={(e) => handleChange(e, 'mobile_number')}
                                ></TextInputForm>
                            </Col>

                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'GST Number'}
                                    labelname={'GST Number'}
                                    name='gst_no'
                                    value={crtCompany != null ? crtCompany.gst_no : ''}
                                    onChange={(e) => handleChange(e, 'gst_no')}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'FSSAI Number'}
                                    labelname={'FSSAI Number'}
                                    name='fssai_code'
                                    value={crtCompany != null ? crtCompany.fssai_code : ''}
                                    onChange={(e) => handleChange(e, 'fssai_code')}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <div>Address</div>
                                <div className='w-100'>
                                    <textarea className='form-cntrl w-100'
                                        placeholder='Address'
                                        labelname={"Address"}
                                        name='address'
                                        value={crtCompany != null ? crtCompany.address : ''}
                                        onChange={(e) => handleChange(e, 'address')} />
                                </div>
                            </Col>
                            {/* <Col lg='4' md='6' xs='12' className='py-3'>
                                <div>Bank Deatils</div>
                                <div className='w-100'>
                                    <textarea className='form-cntrl w-100'
                                        placeholder='Bank Deatils'
                                        labelname={"Bank Deatils"}
                                        name='bank'
                                        value={crtCompany != null ? crtCompany.bank : ''}
                                        onChange={(e) => handleChange(e, 'bank')} />
                                </div>
                            </Col> */}
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'City'}
                                    labelname={'City'}
                                    name='city'
                                    value={crtCompany != null ? crtCompany.city : ''}
                                    onChange={(e) => handleChange(e, 'city')}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'State'}
                                    labelname={'State'}
                                    name='state'
                                    value={crtCompany != null ? crtCompany.state : ''}
                                    onChange={(e) => handleChange(e, 'state')}
                                ></TextInputForm>
                            </Col>

                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'Bill Prefix'}
                                    labelname={'Bill Prefix'}
                                    name='bill_prefix'
                                    value={crtCompany != null ? crtCompany.bill_prefix : ''}
                                    onChange={(e) => handleChange(e, 'bill_prefix')}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'Bank Name'}
                                    labelname={'Bank Name'}
                                    name='bank_name'
                                    value={crtCompany != null ? crtCompany.bank_name : ''}
                                    onChange={(e) => handleChange(e, 'bank_name')}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'Acc No'}
                                    labelname={'Acc No'}
                                    name='acc_no'
                                    value={crtCompany != null ? crtCompany.acc_no : ''}
                                    onChange={(e) => handleChange(e, 'acc_no')}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'IFSC Code'}
                                    labelname={'IFSC Code'}
                                    name='ifsc_code'
                                    value={crtCompany != null ? crtCompany.ifsc_code : ''}
                                    onChange={(e) => handleChange(e, 'ifsc_code')}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'UPI NUmber'}
                                    labelname={'UPI NUmber'}
                                    name='upi_no'
                                    value={crtCompany != null ? crtCompany.upi_no : ''}
                                    onChange={(e) => handleChange(e, 'upi_no')}
                                ></TextInputForm>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Col lg='12' md='12' xs='12' className='py-5 align-self-center'>
                        <div className='text-center'>
                            {crtCompany != null ? (
                                <>
                                    <ToastContainer
                                        position="top-center"
                                        autoClose={3000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme="light"
                                    />
                                    <span className='mx-2'>
                                        <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>
                                    </span>
                                    <span className='mx-2'>
                                        <ClickButton label={<>Cancel</>} onClick={handleClose}></ClickButton>
                                    </span>
                                </>

                            ) : (
                                ""
                            )}
                        </div>
                    </Col>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Company