import React,{useState,useEffect} from 'react'
import { Col, Container, Row} from 'react-bootstrap'
import { PageTitle } from '../components/PageTitle'
import Select from 'react-select'
import { Tables } from '../components/Tables'
const options = [
  { value: 'This Month', label: 'This Month' },
  { value: 'Last Month', label: 'Last Month' },
]
//const ProductTablehead = ["Product Name", 'Quantity']
const ProductTablehead = ['Product Name', 'Current Stock', 'Minimum Stock'];
const ProductData = [{ values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }]
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [salesdata, setSalesData] = useState([]);
  const [expitemData, setexpitemData] = useState([]);
  const [payindata, setpayinData] = useState([]);
  const [payoutdata, setpayoutData] = useState([]);
  const [estimationData, setEstiData] = useState([]);
 
  const [totalsales, settotalsales] = useState(0.00);
  const [totalExpense, settotalExpense] = useState(0.00);
  const [payintotal, setpayintotal] = useState(0.00);
  const [purchasetotal, setpurchasetotal] = useState(0.00);


  const [salefilterValue, setsaleFilterValue] = useState("month");
  const [expenseFilterValue, setExpenseFilterValue] = useState('month');
  const [recivedFilterValue, setrecivedFilterValue] = useState('month');
  const [purchaseFilterValue, setpurchaseFilterValue] = useState('month');
  const [ProductDataupdate, setProductData] = useState([]);
  const lowStockProducts = ProductDataupdate.filter(product => product.crt_stock < product.min_stock);
  const lowStockProductData = lowStockProducts.map(product => ({
    values: [product.product_name, product.crt_stock, product.min_stock]
  }));
  console.log('ProductData',ProductDataupdate);
  const fetchDataproduct = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/product/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      setLoading(false);
      console.log(responseData)
      if (responseData.status === 200) {
        setProductData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  const filteredSaleData = salesdata.filter(item => {
    if (salefilterValue === 'This Month') {
      // Filter data for this month
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // Note: January is 0
      const currentYear = currentDate.getFullYear();
      const itemDate = new Date(item.bill_date);
      return itemDate.getMonth() + 1 === currentMonth && itemDate.getFullYear() === currentYear;
    } else if (salefilterValue === 'Last Month') {
      // Filter data for last month
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth(); // Note: January is 0
      const currentYear = currentDate.getFullYear();
      const itemDate = new Date(item.bill_date);
      if (currentMonth === 0) {
        return itemDate.getMonth() + 1 === 12 && itemDate.getFullYear() === currentYear - 1;
      } else {
        return itemDate.getMonth() + 1 === currentMonth && itemDate.getFullYear() === currentYear;
      }
    }
    return true; // Return true if no filter is applied
  });
  const totalSalesamt = filteredSaleData.reduce((acc, item) => acc + item.total, 0);
  const filteredrecivedData = payindata.filter(item => {
    if (recivedFilterValue === 'This Month') {
      // Filter data for this month
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // Note: January is 0
      const currentYear = currentDate.getFullYear();
      const itemDate = new Date(item.receipt_date);
      return itemDate.getMonth() + 1 === currentMonth && itemDate.getFullYear() === currentYear;
    } else if (recivedFilterValue === 'Last Month') {
      // Filter data for last month
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth(); // Note: January is 0
      const currentYear = currentDate.getFullYear();
      const itemDate = new Date(item.receipt_date);
      if (currentMonth === 0) {
        return itemDate.getMonth() + 1 === 12 && itemDate.getFullYear() === currentYear - 1;
      } else {
        return itemDate.getMonth() + 1 === currentMonth && itemDate.getFullYear() === currentYear;
      }
    }
    return true; // Return true if no filter is applied
  });
  const totalrecivedamt = filteredrecivedData.reduce((acc, item) => acc + item.paid, 0);
  const filtepurchaseData = userData.filter(item => {
    if (purchaseFilterValue === 'This Month') {
      // Filter data for this month
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // Note: January is 0
      const currentYear = currentDate.getFullYear();
      const itemDate = new Date(item.bill_date);
      return itemDate.getMonth() + 1 === currentMonth && itemDate.getFullYear() === currentYear;
    } else if (purchaseFilterValue === 'Last Month') {
      // Filter data for last month
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth(); // Note: January is 0
      const currentYear = currentDate.getFullYear();
      const itemDate = new Date(item.bill_date);
      if (currentMonth === 0) {
        return itemDate.getMonth() + 1 === 12 && itemDate.getFullYear() === currentYear - 1;
      } else {
        return itemDate.getMonth() + 1 === currentMonth && itemDate.getFullYear() === currentYear;
      }
    }
    return true; // Return true if no filter is applied
  });
  const totalpurchaseamt = filtepurchaseData.reduce((acc, item) => acc + item.total, 0);
  useEffect(() => {
    if (salesdata.length > 0) {
      calculate_total(salesdata, 'sales');
  }
  if(expitemData.length > 0){
    calculate_total(expitemData, 'expenses');
  }
  if(payindata.length > 0){
    calculate_total(payindata, 'payin');
    }
  if(userData.length > 0 ){
     calculate_total(userData, 'purchase');
  }
  }, [salesdata,expitemData,payindata,userData]);
  const fetchData = async () => {
    try {
        const companyId = localStorage.getItem('companyId');
        const response = await fetch(' https://node.zentexus.com/purchasebill/list/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                search_text: ''
            })
        });

        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }

        const responseData = await response.json();
        console.log(responseData);
        setLoading(false);
        if (responseData.status === 200) {
            setUserData(responseData.data);
            console.log(setUserData)

        } else {
            throw new Error(responseData.msg);
        }
    } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error.message);
    }
};
const fetchsalesData = async () => {
  try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/saleinvoice/list/' + companyId, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              search_text: ''
          })
      });

      if (!response.ok) {
          throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
          setSalesData(responseData.data);


      } else {
          throw new Error(responseData.msg);
      }
  } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
  }
};
const fetchExpensesItemData = async () => {
  try {
    const companyId = localStorage.getItem('companyId');
    const response = await fetch('https://node.zentexus.com/expenses/item/list/' + companyId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        search_text: ''
      })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    console.log(responseData)
    setLoading(false);
    if (responseData.status === 200) {
      // Set userData to the array of purchase parties
      setexpitemData(responseData.data);
    } else {
      throw new Error(responseData.msg);
    }
  } catch (error) {
    setLoading(false);
  }
};
const fetchDatapayin = async () => {
  try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/payin/list/' + companyId, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              search_text: ''
          })
      });

      if (!response.ok) {
          throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
          setpayinData(responseData.data);
          console.log(responseData)

      } else {
          throw new Error(responseData.msg);
      }
  } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
  }
};
const fetchDatapayout = async () => {
  try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/payout/list/' + companyId, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              search_text: ''
          })
      });

      if (!response.ok) {
          throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
          // Set userData to the array of purchase parties
          setpayoutData(responseData.data);
          console.log(responseData)
      } else {
          throw new Error(responseData.msg);
      }
  } catch (error) {
      setLoading(false);

  }
};
const fetchDataestimation = async () => {
  try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/saleestimation/list/' + companyId, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              search_text: ''
          })
      });

      if (!response.ok) {
          throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
          setEstiData(responseData.data);

      } else {
          throw new Error(responseData.msg);
      }
  } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
  }
};
  const calculate_total = (data,type) => {

    if(type === "sales"){
      console.log('salesrecive')
     var totalSales = data.reduce((acc, sale) => acc + sale.total, 0);
     settotalsales(totalSales);
    }else if(type === "expenses"){
      var totalExpense = data.reduce((acc , expense) => acc + expense.total,0);
      settotalExpense(totalExpense)
    }else if(type === "payin"){
      var totalpayin = data.reduce((acc , payin) => acc + payin.paid,0);
      setpayintotal(totalpayin)
    }else if(type === "purchase"){
      var totalpurchase = data.reduce((acc , purchase) => acc + purchase.total,0);
      setpurchasetotal(totalpurchase)
    }

  };
  const handleFilterChange = (type) => (selectedOption) => {
    if (type === "sale") {
      setsaleFilterValue(selectedOption.value);
    } else if (type === "expense") {
      setExpenseFilterValue(selectedOption.value);
    }else if (type === "recived") {
      setrecivedFilterValue(selectedOption.value);
    }else if (type === "purchase") {
      setpurchaseFilterValue(selectedOption.value);
    }
  };
useEffect(() => {
  
  fetchData();
  fetchsalesData();
  fetchExpensesItemData();
  fetchDatapayin();
  fetchDatapayout();
  fetchDataestimation();
  fetchDataproduct();
 
}, [])
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <PageTitle PageTitle={"Dashboard"} />
          </Col>
        </Row>
      </Container>
      <div>
        <Container fluid >
          <Row>
            <Col lg='8'>
              <Row>
                <Col lg='6' className='py-3'>
                  <div className='main'>
                    <Row>
                      <Col lg='6' className='py-3'>
                        <div className='fs-4'> Sale</div>
                      </Col>
                      <Col lg='6' className='py-3'>
                        <div className='ms-auto w-100'>  <Select 
                        placeholder={"Filter"}
                         options={options} 
                         value={options.find(option => option.value === salefilterValue)}
                        onChange={handleFilterChange('sale')}/></div>
                      </Col>
                      <Col lg='6' className='py-3'>
                        <div> {salefilterValue} Sale </div>
                        <div className='fs-4'> ₹<span>{totalSalesamt}</span></div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg='6' className='py-3'>
                  <div className='main'>
                    <Row>
                      <Col lg='6' className='py-3'>
                        <div className='fs-4'>Expense</div>
                      </Col>
                      <Col lg='6' className='py-3'>
                      
                      </Col>
                      <Col lg='6' className='py-3'>
                        <div> {expenseFilterValue} Expense </div>
                        <div className='fs-4'> ₹<span>{totalExpense}</span></div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                  <Col lg='6' className='py-3'>
                    <div className='main'>
                      <Row>
                        <Col lg='6' className='py-3'>
                          <div className='fs-4'>Received</div>
                        </Col>
                        <Col lg='6' className='py-3'>
                          <div className='ms-auto w-100'>
                              <Select
                                placeholder={"Filter"} 
                                options={options} 
                                value={options.find(option => option.value === recivedFilterValue)}
                          onChange={handleFilterChange('recived')}
                                /></div>
                        </Col>
                        <Col lg='6' className='py-3'>
                          <div> {recivedFilterValue} Received </div>
                          <div className='fs-4'> ₹<span>{totalrecivedamt}</span></div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg='6' className='py-3'>
                    <div className='main'>
                      <Row>
                        <Col lg='6' className='py-3'>
                          <div className='fs-4'>Purchase</div>
                        </Col>
                        <Col lg='6' className='py-3'>
                          <div className='ms-auto w-100'>
                              <Select 
                               placeholder={"Filter"}
                                options={options} 
                                value={options.find(option => option.value === purchaseFilterValue)}
                                onChange={handleFilterChange('purchase')}
                                /></div>
                        </Col>
                        <Col lg='6' className='py-3'>
                          <div> {purchaseFilterValue} Purchase </div>
                          <div className='fs-4'> ₹<span>{totalpurchaseamt}</span></div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg='4' className='py-3'>
                <div className='main'>
                  <div className='text-center py-3'> Low Stock Value</div>
                  <div className='scroll'>
                    <Tables headers={ProductTablehead} body={lowStockProductData} ></Tables>
                  </div>
                </div>
              </Col>
          </Row>
        </Container>
      </div>
    </>

  )
}

export default Dashboard