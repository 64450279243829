import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Table, Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { Calender, DropDown, InstantModel, TextInputForm, DropDownUI } from '../components/Forms';
import { PageHead } from '../components/PageTitle';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate, Navigate } from 'react-router-dom';
import { Buttons, Dltbtn, InstantCreate, ClickButton, Delete } from '../components/Buttons';
import PayMents from '../components/PayMents';
import { BiPlus } from "react-icons/bi";
import { useLocation } from 'react-router-dom';
import { ValueContainer } from 'react-select/animated';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdDeleteForever } from "react-icons/md";
import moment from 'moment/moment'
const DropList = [
  {
    value: 'Admin',
    label: 'Admin'
  },
  {
    value: 'Super Admin',
    label: 'Super Admin'
  },
  {
    value: 'Staff',
    label: 'Staff'
  }
]
const SelectDrCr = [
  {
    value: 'DR',
    label: 'DR'
  },
  {
    value: 'CR',
    label: 'CR'
  }
];
const alltax = [
  {
    value: 'IGST@0%',
    label: 'IGST@0%'
  },
  {
    value: 'GST@0%',
    label: 'GST@0%'
  },
  {
    value: 'IGST@0.25%',
    label: 'IGST@0.25%'
  },
  {
    value: 'GST@0.25%',
    label: 'GST@0.25%'
  },
  {
    value: 'IGST@3%',
    label: 'IGST@3%'
  },
  {
    value: 'GST@3%',
    label: 'GST@3%'
  },
  {
    value: 'IGST@5%',
    label: 'IGST@5%'
  },
  {
    value: 'GST@5%',
    label: 'GST@5%'
  },
  {
    value: 'IGST@12%',
    label: 'IGST@12%'
  },
  {
    value: 'GST@12%',
    label: 'GST@12%'
  },
  {
    value: 'IGST@18%',
    label: 'IGST@18%'
  },
  {
    value: 'GST@18%',
    label: 'GST@18%'
  },
  {
    value: 'IGST@28%',
    label: 'IGST@28%'
  },
  {
    value: 'GST@28%',
    label: 'GST@28%'
  },
]
// saleinvoice start
const SalesInvoiceEntry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [toggled, setToggled] = useState(false)
  const [tax_type, settax_type] = useState('')
  console.log('taxwork', tax_type);
  const handleClick = () => {
    setToggled(!toggled);
  };

  const initialState = type === 'edit' ? { ...rowData } : {
    party_id: '',
    eway_no: '',
    vechile_no: '',
    bill_date: '',
    state_of_supply: '',
    shipp_address: '',
    mobile_number: '',
    billing_address: '',
    total: '',
    paid: '',
    balance_amount: '',
    final_total: '',
    discount_all: '',
    total_discountall: '',
    taxover_amt: '',
    taxover_percentage: '',
    round_off_checkbox: '',
    product: [{ product_id: '', qty: '', unit: '', price_unit: '', discount_percentage: '', discount_amt: '', tax_type: '', tax_percentage: '', tax_amt: '', CGST: '', SGST: '', IGST: '', total_amt: '' }]

  };
  const [formData, setFormData] = useState(initialState);
  console.log(formData);
  const [formDataPartySales, setformDataPartySales] = useState({
    party_id: '',
    party_name: '',
    mobile_number: '',
    alter_number: '',
    email: '',
    company_name: '',
    gst_no: '',
    billing_address: '',
    shipp_address: '',
    opening_balance: '',
    opening_date: '',
    ac_type: ''
  });
  const setLabel = (value, field) => {

    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
    });

  };

  const setLabell = (value, field) => {

    setformDataPartySales({
      ...formDataPartySales,
      [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
    });

  };
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const companyId = localStorage.getItem('companyId');
        const response = await fetch('https://node.zentexus.com/product/list/' + companyId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            search_text: ''
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const responseData = await response.json();
        setLoading(false);
        console.log(responseData)
        if (responseData.status === 200) {
          setProductData(responseData.data);
        } else {
          throw new Error(responseData.msg);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error.message);
      }
    };

    fetchProductData(); // Call fetchData directly in useEffect

  }, []);
  const fetchPartySalesData = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/salesparty/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData)
      setLoading(false);
      if (responseData.status === 200) {
        setPartySalesData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    fetchPartySalesData(); // Call fetchData directly in useEffect
  }, []);
  const fetchUnitData = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/unit/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUnitData(responseData.data);

      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    fetchUnitData(); // Call fetchData directly in useEffect
  }, []);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [productData, setProductData] = useState([]);

  const [UnitData, setUnitData] = useState([])


  const [partySalesData, setPartySalesData] = useState([]);

  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",

    });
  }
  const handleModalSubmit = async () => {
    try {
      if (formDataPartySales.party_name === '' || formDataPartySales.company_name === '') {
        if (formDataPartySales.party_name === '') {
          errorAlert("PartyName is Must");
        } else if (formDataPartySales.company_name === '') {
          errorAlert("Company Name is Must")
        }

      } else {
        console.log(formDataPartySales);
        const companyId = localStorage.getItem('companyId');
        const response = await fetch('https://node.zentexus.com/salesparty/create/' + companyId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formDataPartySales)
        });

        const responseData = await response.json();

        console.log(responseData);

        if (responseData.status === 200) {
          successAlert(responseData.msg)
          setTimeout(() => {
            handleCloseModal();
          }, 2000);
          await fetchPartySalesData();
          setFormData(prevFormData => ({
            ...prevFormData,
            party_id: responseData.data.party_id,
            mobile_number: responseData.data.mobile_number,
            billing_address: responseData.data.billing_address,
            shipp_address: responseData.data.shipp_address // Assuming the response contains the new party_id in data
          }));
        }
        else if (responseData.status === 400) {
          toast.error('Missing required fields!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      console.log(formData);
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/saleinvoice/create/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const responseData = await response.json();

      console.log(responseData);

      if (responseData.status === 200) {
        setFormData({
          party_id: '',
          bill_no: '',
          bill_date: '',
          total: '',
          paid: '',
          balance_amount: '',
          final_total: '',
          discount_all: '',
          total_discountall: '',
          taxover_amt: '',
          taxover_percentage: '',
          round_off_checkbox: '',
          product: [{ product_id: '', qty: '', unit: '', remarks: '', price_unit: '', discount_percentage: '', discount_amt: '', tax_percentage: '', tax_amt: '', total_amt: '' }]

        })
        successAlert(responseData.msg)
        setTimeout(() => {
          navigate("/console/sales/invoice");
        }, 2000);
      }
      else if (responseData.status === 400) {
        toast.error('Missing required fields!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      else {
        setShowAlert(true);
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch(' https://node.zentexus.com/saleinvoice/update/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ // Include the company ID in the request
          invoice_id: rowData.invoice_id,
          party_id: formData.party_id,
          invoice_type: formData.invoice_type,
          bill_date: formData.bill_date,
          total: formData.total,
          paid: formData.paid,
          balance: formData.balance_amount,
          product: formData.product
        }),
      });
      console.log('josnnnn', JSON.stringify({ // Include the company ID in the request
        invoice_id: rowData.invoice_id,
        party_id: formData.party_id,
        invoice_type: formData.invoice_type,
        bill_date: formData.bill_date,
        total: formData.total,
        paid: formData.paid,
        balance: formData.balance_amount,
        product: formData.product
      }))

      if (!response.ok) {
        throw new Error('Failed to update company');
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        toast.success('SalesInvoivce updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/sales/invoice");
        }, 2000);

        // Navigate to the user list page after a delay

      } else {
        console.error(responseData.msg || 'Unknown error occurred during update');
      }
    } catch (error) {
      console.error('Error updating product:', error.msg);
    }

    setLoading(false);
  };
  const handleAddRow = () => {
    setFormData(prevState => ({
      ...prevState,
      product: [...prevState.product, {/* New product row */ }]
    }));
  };

  // Delete a row from the product list
  const handleDeleteRow = (index) => {
    setFormData(prevState => ({
      ...prevState,
      product: prevState.product.filter((_, i) => i !== index)
    }));
  };

  const handleChangePartySales = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setformDataPartySales({
      ...formDataPartySales,
      [fieldName]: value
    });
  };

  // Handle change in total amount
  const handleTotalChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      total: e.target.value
    }));
  };

  // Handle change in paid amount
  const handlePaidChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      paid: e.target.value
    }));
  };
  const calculateTotal = (product) => {
    const totalAmount = product.reduce((acc, row) => acc + parseFloat(row.total_amt || 0), 0);

    setFormData((prevData) => ({
      ...prevData,
      total: totalAmount.toFixed(2),

    }));
  };
  // Handle key press event
  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      const lastRow = formData.product[formData.product.length - 1];
      if (lastRow.product_id && lastRow.qty && lastRow.unit && lastRow.price_unit && lastRow.total_amt) {
        handleAddRow();
      } else {
        toast.error('Please fill in all fields in the last row before adding a new row');
      }
    }
  };



  const handleChange = (e, fieldName, rowIndex) => {
    const value = e.target ? e.target.value : e.value;
    var updatedFormData = { ...formData };

    if (rowIndex !== undefined) {
      updatedFormData = {
        ...formData,
        product: formData.product.map((row, index) =>
          index === rowIndex ? { ...row, [fieldName]: value } : row
        )
      };
    } else {
      updatedFormData = {
        ...formData,
        [fieldName]: value
      };
    }

    updatedFormData.product.forEach((row, index) => {
      const qty = parseFloat(row.qty);
      const price = parseFloat(row.price_unit);
      const discountPercentage = parseFloat(row.discount_percentage);
      const discountInputAmount = parseFloat(row.discount_amt);
      const taxPercentage = parseFloat(row.tax_percentage);
      const taxtype = row.tax_type;
      console.log('handlepro', taxtype)

      let sgst = 0, cgst = 0, igst = 0;


      let crtdiscountFiled = 0.00;

      let subtotal = isNaN(qty) || isNaN(price) ? 0 : (qty * price);
      if (fieldName === "price_unit") {
        crtdiscountFiled = isNaN(discountPercentage) ? 0 : (isNaN(subtotal) ? 0 : (subtotal * discountPercentage) / 100);
        updatedFormData.product[index].discount_amt = crtdiscountFiled;

      }
      if (fieldName === "discount_percentage") {
        crtdiscountFiled = isNaN(discountPercentage) ? 0 : (isNaN(subtotal) ? 0 : (subtotal * discountPercentage) / 100);
        updatedFormData.product[index].discount_amt = crtdiscountFiled;
      } else if (fieldName === "discount_amt") {
        crtdiscountFiled = isNaN(discountInputAmount) ? 0 : discountInputAmount;
        const cal = (crtdiscountFiled / subtotal) * 100;
        updatedFormData.product[index].discount_percentage = isNaN(cal) ? 0 : parseFloat(cal).toFixed(2);
      }
      let tmpsubtotal = 0;
      if (fieldName === "tax_percentage") {
        tmpsubtotal = subtotal - (isNaN(discountInputAmount) ? 0 : discountInputAmount);
      } else {
        tmpsubtotal = subtotal - (isNaN(crtdiscountFiled) ? 0 : crtdiscountFiled);
      }


      console.log('siva', tmpsubtotal);
      console.log('siva tax', taxPercentage);
      console.log('siva disper', discountPercentage);
      console.log('discountInputAmount', discountInputAmount);
      console.log('subtotal', subtotal);
      console.log('crtdiscountFiled', crtdiscountFiled)
      let taxAmount = isNaN(tmpsubtotal) || isNaN(taxPercentage) ? 0 : (tmpsubtotal * taxPercentage) / 100;
      console.log('taxamount', taxAmount);
      let finalAmount = tmpsubtotal + taxAmount;
      if (taxtype === "IGST") {
        igst = taxAmount;
      } else {
        var gstamount = taxAmount / 2;
        cgst = gstamount;
        sgst = gstamount;
      }
      updatedFormData.product[index].SGST = sgst.toFixed(2);
      updatedFormData.product[index].CGST = cgst.toFixed(2);
      updatedFormData.product[index].IGST = igst.toFixed(2);
      updatedFormData.product[index].tax_amt = taxAmount.toFixed(2);
      updatedFormData.product[index].total_amt = finalAmount.toFixed(2);


    });

    const totalAmount = updatedFormData.product.reduce((acc, row) => acc + parseFloat(row.total_amt || 0), 0)
    updatedFormData.total = totalAmount.toFixed(2);

    setFormData(updatedFormData);
  };

  useEffect(() => {
    calculateTotal(formData.product);

  }, [formData.product]);
  useEffect(() => {
    const updateBalance = () => {

      const total = parseFloat(formData.total);
      const paid = parseFloat(formData.paid);


      const balance = !isNaN(total) && !isNaN(paid) ? total - paid : '';

      setFormData(prevFormData => ({
        ...prevFormData,
        balance_amount: balance.toString()
      }));
    };

    updateBalance();
  }, [formData.total, formData.paid]);

  function calculateTax(product, tax_percentage, index, taxType) {
    let updatedFormData = { ...formData };
    let sgst = 0, cgst = 0, igst = 0;
    console.log('sivabbbb', product);

    let producttotal = parseFloat(product.total_amt)
    console.log('sivaproducttotaltbbb', producttotal);
    console.log('tax_percentage', tax_percentage)
    let taxAmt = producttotal * parseFloat(tax_percentage.match(/\d+\.*\d*/)[0]) / 100;
    console.log("taxamt", taxAmt);
    console.log('sivataxAmountbbb', taxAmt);
    if (taxType === 'GST') {
      var taxAmount = taxAmt / 2;
      sgst = taxAmount;
      cgst = taxAmount;
    } else if (taxType === 'IGST') {
      igst = taxAmt;
    }

    // Update form data with tax amounts
    updatedFormData.product[index].SGST = sgst.toFixed(2);
    updatedFormData.product[index].CGST = cgst.toFixed(2);
    updatedFormData.product[index].IGST = igst.toFixed(2);
    updatedFormData.product[index].tax_type = taxType;

    setFormData(updatedFormData);
  }


  useEffect(() => {
    // Check if party_id is selected
    if (formData.party_id) {
      // Find the selected party in partySalesData
      const selectedParty = partySalesData.find(party => party.party_id === formData.party_id);

      if (selectedParty) {
        // Update formData with party data
        setFormData(prevFormData => ({
          ...prevFormData,
          mobile_number: selectedParty.mobile_number || '',
          billing_address: selectedParty.billing_address || '',
          shipp_address: selectedParty.shipp_address || ''
        }));
      }
    }

  }, [formData.party_id, partySalesData]);





  const defaultDate = type === 'edit' ? formData.bill_date : new Date().toISOString();

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <div className='main'>
        <Container fluid className='px-0'>
          <Row>
            <Col lg='12' className='py-3'><PageHead pagehead={<>Sales Invoice</>} onClick={() => navigate(-1)} /></Col>
            <Col lg='12' className='py-3'>
              <Table>
                <tr>
                  <td>
                    <div className='party-on'>
                      <Container>
                        <Row>
                          <Col lg='4' className='py-3'>
                            <div className='d-flex'>
                              <div className='w-250'>
                                <DropDownUI
                                  optionlist={partySalesData.map(user => ({
                                    value: user.party_id,
                                    label: user.party_name
                                  }))}
                                  placeholder='Party Name'
                                  labelname='Party Name'
                                  name='party_id'
                                  value={formData.party_id}
                                  onChange={(updatedformData) => setFormData({ ...formData, party_id: updatedformData.party_id })}
                                />
                              </div>
                              <div className='instant-btn'>
                                <InstantCreate label={<BiPlus />} className='instant-add' onClick={handleShowModal}></InstantCreate>
                              </div>
                            </div>
                          </Col>
                          <Col lg='4' className='py-3'>
                            <div className="W-50">
                              <TextInputForm placeholder={'Mobile Number'}
                                labelname={'Mobile Number'}
                                name='mobile_number'
                                value={formData.mobile_number}
                                onChange={(e) => handleChange(e, 'mobile_number')} />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg='4' className='py-3'>
                            <label className='pb-2'>Billing Address</label>
                            <textarea
                              className='form-cntrl form-control'
                              placeholder={'Address'}
                              labelname={'Address'}
                              name='billing_address '
                              value={formData.billing_address}
                              onChange={(e) => handleChange(e, 'billing_address')} />
                          </Col>
                          <Col lg='4' className='py-3'>
                            <label className='pb-2'>Shipping Address</label>
                            <textarea className='form-cntrl form-control'
                              placeholder={'Address'}
                              labelname={'Address'}
                              name='shipp_address'
                              value={formData.shipp_address}
                              onChange={(e) => handleChange(e, 'shipp_address')} />
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </td>
                  <td className='mx-auto float-end'>

                    <div>
                      <Calender setLabel={(date) => setLabel(date, 'bill_date')} selectedDate={formData.bill_date} calenderlabel="Bill Date" />
                    </div>
                    <div>
                      <TextInputForm placeholder={'EWAY Number'}
                        labelname={'EWAY Number'}
                        name='eway_no'
                        value={type === 'edit' ? formData.eway_no : formData.eway_no}
                        onChange={(e) => handleChange(e, 'eway_no')} />
                    </div>
                    <div> <TextInputForm placeholder={'VECHILE Number'}
                      labelname={'VECHILE Number'}
                      name='vechile_no'
                      value={type === 'edit' ? formData.vechile_no : formData.vechile_no}
                      onChange={(e) => handleChange(e, 'vechile_no')} /> </div>
                    <div> <TextInputForm placeholder={'State of Supply'}
                      labelname={'STATE OF SUPPLY'}
                      name='state_of_supply'
                      value={type === 'edit' ? formData.state_of_supply : formData.state_of_supply}
                      onChange={(e) => handleChange(e, 'state_of_supply')} /> </div>
                  </td>
                </tr>
              </Table>
            </Col>
          </Row>
        </Container>
      </div >
      <div className='main my-3'>
        <div>
          <Container fluid className='px-0'>
            <Row>
              <Col lg='12' className='py-3'>
                <div>No Of Products : <span>{formData.product.length}</span></div>
              </Col>
              <Col lg='12' md='6' xs='12'>
                <div className="py-2 category-table bill">
                  <Table>
                    <thead className=''>
                      <tr>
                        <th rowSpan={2}>#</th>
                        <th rowSpan={2} className='wrdbrk w-20'>Product Name</th>
                        <th rowSpan={2} className='wrdbrk w-7'>Qty</th>
                        <th rowSpan={2} className='wrdbrk w-10'>Unit</th>
                        <th rowSpan={2} className='wrdbrk w-7'>Price / Unit</th>
                        <th colSpan={2} className='wrdbrk w-15'>Discount</th>
                        <th colSpan={2} className='wrdbrk w-15' >Tax</th>
                        <th rowSpan={2} className='wrdbrk'>Amount</th>
                      </tr>
                      <tr>
                        <th>%</th>
                        <th className='w-10'>Amount</th>
                        <th >%</th>
                        <th className='w-10'>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.product && formData.product.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>

                            <div>
                              <DropDownUI
                                optionlist={productData.map(user => ({
                                  value: user.product_id,
                                  label: user.product_name
                                }))}
                                placeholder='ProductName'
                                name='product_id'
                                value={row.product_id}
                                onChange={(updatedFormData) => setFormData({ ...formData, product: formData.product.map((r, i) => i === index ? { ...r, product_id: updatedFormData.product_id } : r) })}
                                onKeyDown={(e) => handleKeyPress(e, index)}
                                autoFocus={index === formData.product.length - 1}
                              />
                            </div>
                            <div>

                              <TextInputForm
                                placeholder={'Remarks'}
                                name='remarks'
                                value={row.remarks}
                                onChange={(e) => handleChange(e, 'remarks', index)}
                                onKeyDown={(e) => handleKeyPress(e, index)}
                              ></TextInputForm>
                            </div>
                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'qty'}
                              name='qty'
                              value={row.qty}
                              onChange={(e) => handleChange(e, 'qty', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                            ></TextInputForm>
                          </td>
                          <td>
                            <DropDownUI
                              optionlist={productData.find(product => product.product_id === row.product_id)?.unit_array.map(unit => ({
                                value: unit.unit_id,
                                label: unit.unit_name
                              })) || []}
                              placeholder='Unit'
                              name='unit'
                              value={row.unit}
                              onChange={(updatedFormData) => setFormData({ ...formData, product: formData.product.map((r, i) => i === index ? { ...r, unit: updatedFormData.unit } : r) })}
                              onKeyDown={(e) => handleKeyPress(e, index)} />
                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'price'}
                              name='price'
                              value={row.price_unit}
                              onChange={(e) => handleChange(e, 'price_unit', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                            />

                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'discount_percentage'}
                              name='discount_percentage'
                              value={row.discount_percentage}
                              onChange={(e) => handleChange(e, 'discount_percentage', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                              disabled={formData.discount_all}
                            />
                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'discount_amt'}
                              name='discount_amt'
                              value={row.discount_amt}
                              onChange={(e) => handleChange(e, 'discount_amt', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                              disabled={formData.total_discountall}
                            />
                          </td>
                          <td className='w-10 text-left'>
                            <DropDownUI
                              optionlist={alltax}
                              placeholder='Tax'
                              name='tax_percentage'
                              value={row.tax_percentage}
                              onChange={(updatedFormData) => {
                                const selectedTax = alltax.find(tax => tax.value === updatedFormData.tax_percentage);
                                console.log('selecedtax', selectedTax);
                                const taxType = selectedTax ? selectedTax.value.split('@')[0] : '';
                                console.log('taxtype', taxType);
                                settax_type(taxType);
                                setFormData(prevFormData => ({
                                  ...prevFormData,
                                  product: prevFormData.product.map((r, i) => i === index ? { ...r, tax_percentage: updatedFormData.tax_percentage } : r)
                                }));
                                calculateTax(formData.product[index], updatedFormData.tax_percentage, index, taxType);
                                handleChange({ value: parseFloat(updatedFormData.tax_percentage.match(/\d+\.*\d*/)[0]) }, 'tax_percentage', index);
                                //handleChange({ value: updatedFormData.taxType}, 'tax_type', index);
                              }}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                            />
                          </td>


                          <td>
                            <TextInputForm
                              placeholder={'tax_amt'}
                              name='tax_amt'
                              value={row.tax_amt}
                              onChange={(e) => handleChange(e, 'tax_amt', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}

                            />
                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'amt'}
                              name='total_amt'
                              value={row.total_amt}
                              onChange={(e) => handleChange(e, 'total_amt', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                            />
                          </td>
                          <td className='d-none' >
                            <div className='d-none'>
                              {index === formData.product.length - 1 && (
                                <ClickButton label={<>Add More</>} onClick={handleAddRow} />
                              )}
                            </div>
                          </td>
                          <td>
                            <Delete onClick={() => handleDeleteRow(index)} label={<MdDeleteForever />}></Delete>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='my-3'>
          <Container>
            <Row>
              <Col lg='5'>
                {/* <PayMents /> */}
              </Col>
              <Col lg='7'>
                <div className='inside my-3'>
                  <Table>
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td className='w-25'>
                        <TextInputForm
                          placeholder={'Total'}
                          name='total'
                          value={type === 'edit' ? formData.total : formData.total}
                          onChange={handlePaidChange}
                        />

                      </td>
                    </tr>


                    <tr>

                    </tr>
                    <tr>
                      {/* <td>Paid</td>
                      <td></td>
                      <td className='w-25'>
                        <TextInputForm
                          placeholder={'Paid'}
                          name='paid'

                          value={type === 'edit' ? formData.paid : formData.paid}
                          onChange={handlePaidChange}
                        />
                      </td> */}
                    </tr>
                    <tr>
                      {/* <td>Balance</td>
                      <td></td>
                      <td className='w-25'>
                        <TextInputForm
                          placeholder={'balance'}
                          name='balance_amount'
                          value={type === 'edit' ? formData.balance_amount : formData.balance_amount}
                          onChange={(e) => handleChange(e, 'balance_amount')}
                        />
                      </td> */}
                    </tr>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='main stick-bottom'>
        <div className='w-50 ms-auto'>
          <Container>
            <Row>
              <Col lg="12">
                <div className='text-center'>
                  {type === 'edit' ? (
                    <>
                      <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <span className='mx-2'>
                        <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                      </span>

                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/sales/invoice")}></ClickButton>
                      </span>
                    </>

                  ) : (
                    <>
                      <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <span className='mx-2'>
                        <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>

                      </span>

                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/sales/invoice")}></ClickButton>
                      </span>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div>
        <Modal show={showModal} onHide={handleCloseModal} size='lg'>
          <Modal.Header >
            <PageHead pagehead={<>Party Creation</>} onClick={handleCloseModal} />
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg="4" className='py-3'>
                  <TextInputForm placeholder={'Company Name'}
                    labelname={'Company Name'}
                    name='company_name'
                    value={formDataPartySales.company_name}
                    onChange={(e) => handleChangePartySales(e, 'company_name')} />
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm placeholder={'Party Name'}
                    labelname={'Party Name'}
                    name='party_name'
                    value={formDataPartySales.party_name}
                    onChange={(e) => handleChangePartySales(e, 'party_name')} />
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm placeholder={'Mobile Number'}
                    labelname={'Mobile Number'}
                    name='mobile_number'
                    value={formDataPartySales.mobile_number}
                    onChange={(e) => handleChangePartySales(e, 'mobile_number')} />
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm placeholder={'GST NO'}
                    labelname={'GST NO'}
                    name='gst_no'
                    value={formDataPartySales.gst_no}
                    onChange={(e) => handleChangePartySales(e, 'gst_no')} />
                </Col>
              </Row>
            </Container>
            <Tabs
              defaultActiveKey="address"
              className="mb-3"
            >
              <Tab eventKey="address" title="Address">
                <Container>
                  <Row>
                    <Col lg="6" className='py-3'>
                      <TextInputForm placeholder={'Alter Number'}
                        labelname={'Alter Number'}
                        name='alter_number'
                        value={formDataPartySales.alter_number}
                        onChange={(e) => handleChangePartySales(e, 'alter_number')} />
                    </Col>
                    <Col lg="6" className='py-3'>
                      <TextInputForm
                        placeholder={'E Mail'}
                        labelname={'E Mail'}
                        name='email '
                        value={formDataPartySales.email}
                        onChange={(e) => handleChangePartySales(e, 'email')} />
                    </Col>
                    <Col lg='6' className='py-3'>
                      <label className='pb-2'>Billing Address</label>
                      <textarea
                        className='form-cntrl form-control'
                        placeholder={'Address'}
                        labelname={'Address'}
                        name='billing_address '
                        value={formDataPartySales.billing_address}
                        onChange={(e) => handleChangePartySales(e, 'billing_address')} />
                    </Col>
                    <Col lg='6' className='py-3'>
                      <label className='pb-2'>Shipping Address</label>
                      <textarea className='form-cntrl form-control'
                        placeholder={'Address'}
                        labelname={'Address'}
                        name='shipp_address'
                        value={formDataPartySales.shipp_address}
                        onChange={(e) => handleChangePartySales(e, 'shipp_address')} />
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="credit-balance" title="Credit & Balance">
                <Container>
                  <Row>
                    <Col lg="4" className='py-3'>
                      <div className='w-100'>
                        <Calender setLabel={(date) => setLabell(date, 'opening_date')} selectedDate={formDataPartySales.opening_date} calenderlabel="Opening Date" />

                      </div>
                    </Col>
                    <Col lg="4" className='py-3'>
                      <div className='w-100'>
                        <DropDownUI
                          optionlist={SelectDrCr}
                          placeholder='Select DR or CR'
                          labelname={<> Select DR or CR </>}
                          name='ac_type'
                          value={formDataPartySales.ac_type}
                          onChange={(updatedformDataPartySales) => setformDataPartySales({ ...formDataPartySales, ac_type: updatedformDataPartySales.ac_type })}
                        />
                      </div>
                    </Col>
                    <Col lg="4" className='py-3'>
                      <TextInputForm labelname={"Opening Balance"}
                        placeholder={'Opening Balance'}
                        name='opening_balance'
                        value={formDataPartySales.opening_balance}
                        onChange={(e) => handleChangePartySales(e, 'opening_balance')} />
                    </Col>
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <ClickButton label={<>Cancel</>} onClick={handleCloseModal} >
              Close
            </ClickButton>
            <ClickButton label={<>Submit</>} onClick={handleModalSubmit}   >
              Save Changes
            </ClickButton>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
// saleinvoice end

// EstimationEntry start
const EstimationEntry = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const navigate = useNavigate();
  const [tax_type, settax_type] = useState('')
  console.log('taxwork', tax_type);
  const [formDataPartySales, setformDataPartySales] = useState({
    party_id: '',
    party_name: '',
    mobile_number: '',
    alter_number: '',
    email: '',
    company_name: '',
    gst_no: '',
    billing_address: '',
    shipp_address: '',
    opening_balance: '',
    opening_date: '',
    ac_type: ''
  });
  const initialState = type === 'edit' ? { ...rowData } : {
    party_id: '',
    bill_no: '',
    estimate_date: '',
    total: '',
    paid: '',
    balance_amount: '',
    final_total: '',
    discount_all: '',
    total_discountall: '',
    taxover_amt: '',
    taxover_percentage: '',
    round_off_checkbox: '',
    product: [{ product_id: '', qty: '', unit: '', price_unit: '', discount_percentage: '', discount_amt: '', tax_type: '', tax_percentage: '', tax_amt: '', total_amt: '' }]

  };
  const [formData, setFormData] = useState(initialState);


  const handleChangePartySales = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setformDataPartySales({
      ...formDataPartySales,
      [fieldName]: value
    });
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
    });
  };
  const setLabels = (value, field) => {
    setformDataPartySales({
      ...formDataPartySales,
      [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
    });
  };
  useEffect(() => {
    calculateTotal(formData.product);
  }, [formData.product]);
  useEffect(() => {
    const updateBalance = () => {
      // Ensure total and paid are not empty strings and convert them to numbers
      const total = parseFloat(formData.total);
      const paid = parseFloat(formData.paid);

      // Calculate balance_amount by subtracting paid from total
      const balance = !isNaN(total) && !isNaN(paid) ? total - paid : '';

      // Update the state with the new balance_amount
      setFormData(prevFormData => ({
        ...prevFormData,
        balance_amount: balance.toString()
      }));
    };

    updateBalance();
  }, [formData.total, formData.paid]);



  const handleTotalChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      total: e.target.value
    }));
  };

  const handlePaidChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      paid: e.target.value
    }));
  };


  // useEffect(() => {
  //   // Calculate balance amount whenever final_total or paid changes
  //   const balance = parseFloat(formData.final_total || 0) - parseFloat(formData.paid || 0);
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     balance_amount: balance.toFixed(2), // Update balance_amount state
  //   }));
  // }, [formData.final_total, formData.paid]);


  const handleChange = (e, fieldName, rowIndex) => {
    const value = e.target ? e.target.value : e.value;
    var updatedFormData = { ...formData };


    if (rowIndex !== undefined) {

      updatedFormData = {
        ...formData,
        product: formData.product.map((row, index) =>
          index === rowIndex ? { ...row, [fieldName]: value } : row
        )
      };

    } else {

      updatedFormData = {
        ...formData,
        [fieldName]: value
      };
    }


    updatedFormData.product.forEach((row, index) => {
      const qty = parseFloat(row.qty);
      const price = parseFloat(row.price_unit);
      const discountPercentage = parseInt(row.discount_percentage);
      const discountInputAmount = parseInt(row.discount_amt);
      const taxPercentage = parseFloat(row.tax_percentage);
      const taxtype = row.tax_type;
      let sgst = 0, cgst = 0, igst = 0;
      var crtdiscountFiled = 0.00;




      let subtotal = isNaN(qty) || isNaN(price) ? 0 : (qty * price);

      if (fieldName === "discount_percentage") {
        crtdiscountFiled = isNaN(discountPercentage) ? 0 : (isNaN(subtotal) ? 0 : (subtotal * discountPercentage) / 100)

        updatedFormData.product[rowIndex].discount_amt = crtdiscountFiled;

      } else if (fieldName === "discount_amt") {
        crtdiscountFiled = isNaN(discountInputAmount) ? 0 : discountInputAmount;
        var cal = (discountInputAmount / subtotal) * 100;
        cal = isNaN(cal) ? 0 : parseFloat(cal).toFixed(2)
        updatedFormData.product[rowIndex].discount_percentage = cal;
      } else {
        crtdiscountFiled = isNaN(discountInputAmount) ? 0 : discountInputAmount;
      }


      // let discount = isNaN(discountPercentage) ? isNaN(discountInputAmount) ? 0 : discountInputAmount : (isNaN(subtotal) || isNaN(discountPercentage) ? 0 : (subtotal * discountPercentage) / 100);

      console.log(crtdiscountFiled)
      let tmpsubtotal = subtotal - (isNaN(crtdiscountFiled) ? 0 : crtdiscountFiled);

      console.log(taxPercentage);

      let taxAmount = isNaN(tmpsubtotal) || isNaN(taxPercentage) ? 0 : (tmpsubtotal * taxPercentage) / 100;
      let finalAmount = tmpsubtotal + taxAmount;
      if (taxtype === "IGST") {
        igst = taxAmount;
      } else {
        var gstamount = taxAmount / 2;
        cgst = gstamount;
        sgst = gstamount;
      }
      updatedFormData.product[index].SGST = sgst.toFixed(2);
      updatedFormData.product[index].CGST = cgst.toFixed(2);
      updatedFormData.product[index].IGST = igst.toFixed(2);
      updatedFormData.product[index].tax_amt = taxAmount
        .toFixed(2);
      updatedFormData.product[index].total_amt = finalAmount.toFixed(2);


    });
    const totalAmount = updatedFormData.product.reduce((acc, row) => acc + parseFloat(row.total_amt || 0), 0)
    updatedFormData.total = totalAmount.toFixed(2);



    setFormData(updatedFormData);

  };

  function calculateTax(product, tax_percentage, index, taxType) {
    let updatedFormData = { ...formData };
    let sgst = 0, cgst = 0, igst = 0;
    console.log('sivabbbb', product);
    // Calculate tax amount based on tax type
    // let taxAmount = parseFloat(product.tax_amt);
    let producttotal = parseFloat(product.total_amt)
    console.log('sivaproducttotaltbbb', producttotal);
    console.log('tax_percentage', tax_percentage)
    let taxAmt = producttotal * parseFloat(tax_percentage.match(/\d+\.*\d*/)[0]) / 100;
    console.log("taxamt", taxAmt);
    console.log('sivataxAmountbbb', taxAmt);
    if (taxType === 'GST') {
      var taxAmount = taxAmt / 2;
      sgst = taxAmount;
      cgst = taxAmount;
    } else if (taxType === 'IGST') {
      igst = taxAmt;
    }

    // Update form data with tax amounts
    updatedFormData.product[index].SGST = sgst.toFixed(2);
    updatedFormData.product[index].CGST = cgst.toFixed(2);
    updatedFormData.product[index].IGST = igst.toFixed(2);
    updatedFormData.product[index].tax_type = taxType;

    setFormData(updatedFormData);
  }

  const calculateTotal = (product) => {
    const totalAmount = product.reduce((acc, row) => acc + parseFloat(row.total_amt || 0), 0);

    setFormData((prevData) => ({
      ...prevData,
      total: totalAmount.toFixed(2),

    }));
  };
  const handleAddRow = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      product: [...prevFormData.product, { product_id: '', qty: '', unit: '', price_unit: '', discount_percentage: '', discount_amt: '', tax_percentage: '', tax_amt: '', total_amt: '' }]
    }));
  };

  const handleDeleteRow = (index) => {
    if (formData.product.length === 1) {
      return;
    }

    const updatedRows = formData.product.filter((row, rowIndex) => rowIndex !== index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      product: updatedRows
    }));
  };
  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      const lastRow = formData.product[formData.product.length - 1];
      if (
        lastRow.product_id &&
        lastRow.qty &&
        lastRow.unit &&
        lastRow.price_unit &&
        lastRow.total_amt
      ) {
        handleAddRow();
      } else {
        toast.error('Please fill in all fields in the last row before adding a new row');
      }
    }
  };
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [partySalesData, setPartySalesData] = useState([]);
  const fetchPartySalesData = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/salesparty/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData)
      setLoading(false);
      if (responseData.status === 200) {
        setPartySalesData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    fetchPartySalesData(); // Call fetchData directly in useEffect
  }, []);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",

    });
  }

  const SavePartySales = async () => {
    try {
      if (formDataPartySales.party_name === '' || formDataPartySales.mobile_number === '' || formDataPartySales.company_name === '') {
        if (formDataPartySales.party_name === '') {
          errorAlert("PartyName is Must");
        } else if (formDataPartySales.mobile_number === '') {
          errorAlert("Mobile Number is Must");
        } else if (formDataPartySales.company_name === '') {
          errorAlert("Company Name is Must")
        }

      } else {
        const companyId = localStorage.getItem('companyId');
        const response = await fetch('https://node.zentexus.com/salesparty/create/' + companyId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formDataPartySales)
        });
        console.log(formDataPartySales);
        const responseData = await response.json();

        console.log('responseDatasalesparty', responseData);

        if (responseData.status === 200) {
          successAlert(responseData.msg)
          setTimeout(() => {

            handleCloseModal();
          }, 2000);
          await fetchPartySalesData();
          setFormData(prevFormData => ({
            ...prevFormData,
            party_id: responseData.data.party_id,
            mobile_number: responseData.data.mobile_number,
            billing_address: responseData.data.billing_address,
            shipp_address: responseData.data.shipp_address // Assuming the response contains the new party_id in data
          }));
          // Update formData with the new party_id

          console.log('formData', formData);

        }
        else if (responseData.status === 400) {
          toast.error('Missing required fields!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  useEffect(() => {
    // Check if party_id is selected
    if (formData.party_id) {
      // Find the selected party in partySalesData
      const selectedParty = partySalesData.find(party => party.party_id === formData.party_id);

      if (selectedParty) {
        // Update formData with party data
        setFormData(prevFormData => ({
          ...prevFormData,
          mobile_number: selectedParty.mobile_number || '',
          billing_address: selectedParty.billing_address || '',
          shipp_address: selectedParty.shipp_address || ''
        }));
      }
    }

  }, [formData.party_id, partySalesData]);
  const handleModalSubmit = () => {
    // Update factoryData state with the new value entered in the modal
    const newFactoryName = formDataPartySales.party_name;
    setPartySalesData(prevState => [...prevState, { party_name: newFactoryName }]);
    setFormData(prevState => ({
      ...prevState,
      party_id: newFactoryName
    }));
    handleCloseModal();
    SavePartySales();
  };
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const companyId = localStorage.getItem('companyId');
        const response = await fetch('https://node.zentexus.com/product/list/' + companyId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            search_text: ''
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const responseData = await response.json();
        setLoading(responseData);
        console.log(responseData)
        if (responseData.status === 200) {
          setProductData(responseData.data);
        } else {
          throw new Error(responseData.msg);
        }
      } catch (error) {
        setLoading();
        console.error('Error fetching data:', error.message);
      }
    };

    fetchProductData(); // Call fetchData directly in useEffect

  }, []);
  const [UnitData, setUnitData] = useState([])
  const fetchUnitData = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/unit/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUnitData(responseData.data);

      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    fetchUnitData(); // Call fetchData directly in useEffect
  }, []);
  const handleSubmit = async () => {
    try {

      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/saleestimation/create/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      console.log(formData);
      const responseData = await response.json();

      console.log(responseData);

      if (responseData.status === 200) {
        setFormData({
          party_id: '',
          bill_no: '',
          bill_date: '',
          total: '',
          paid: '',
          balance_amount: '',
          final_total: '',
          discount_all: '',
          total_discountall: '',
          taxover_amt: '',
          taxover_percentage: '',
          round_off_checkbox: '',
          product: [{ product_id: '', qty: '', unit: '', price_unit: '', discount_percentage: '', discount_amt: '', tax_percentage: '', tax_amt: '', total_amt: '' }]

        })
        successAlert(responseData.msg)
        setTimeout(() => {
          navigate("/console/sales/estimation");
        }, 2000);
      }
      else if (responseData.status === 400) {
        toast.error('Missing required fields!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      else {
        setShowAlert(true);
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch(' https://node.zentexus.com/saleestimation/update/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ // Include the company ID in the request
          estimate_id: rowData.estimate_id,
          party_id: formData.party_id,
          estimate_date: formData.estimate_date,
          product: formData.product,
          total: formData.total,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update company');
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        toast.success('User updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/purchase/bill/create");
        }, 2000);

        // Navigate to the user list page after a delay

      } else {
        console.error(responseData.msg || 'Unknown error occurred during update');
      }
    } catch (error) {
      console.error('Error updating product:', error.msg);
    }

    setLoading(false);
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <div className='main'>
        <Container fluid className='px-0'>
          <Row>
            <Col lg='12' className='py-3'><PageHead pagehead={<>Estimation</>} onClick={() => navigate(-1)} /></Col>
            <Col lg='12' className='py-3'>
              <Table>
                <tr>
                  <td>
                    <div className='party-on'>
                      <Container>
                        <Row>
                          <Col lg='4' className='py-3'>
                            <div className='d-flex'>
                              <div className='w-250'>
                                <DropDownUI
                                  optionlist={partySalesData.map(user => ({
                                    value: user.party_id,
                                    label: user.party_name
                                  }))}
                                  placeholder='Party Name'
                                  labelname='Party Name'
                                  name='party_id'
                                  value={formData.party_id}
                                  onChange={(updatedformData) => setFormData({ ...formData, party_id: updatedformData.party_id })}
                                />
                              </div>
                              <div className='instant-btn'>
                                <InstantCreate label={<BiPlus />} className='instant-add' onClick={handleShowModal}></InstantCreate>
                              </div>
                            </div>
                          </Col>
                          <Col lg='4' className='py-3'>
                            <div className="W-50">
                              <TextInputForm placeholder={'Mobile Number'}
                                labelname={'Mobile Number'}
                                name='mobile_number'
                                value={formData.mobile_number}
                                onChange={(e) => handleChange(e, 'mobile_number')} />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg='4' className='py-3'>
                            <label className='pb-2'>Billing Address</label>
                            <textarea
                              className='form-cntrl form-control'
                              placeholder={'Address'}
                              labelname={'Address'}
                              name='billing_address '
                              value={formData.billing_address}
                              onChange={(e) => handleChange(e, 'billing_address')} />
                          </Col>
                          <Col lg='4' className='py-3'>
                            <label className='pb-2'>Shipping Address</label>
                            <textarea className='form-cntrl form-control'
                              placeholder={'Address'}
                              labelname={'Address'}
                              name='shipp_address'
                              value={formData.shipp_address}
                              onChange={(e) => handleChange(e, 'shipp_address')} />
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </td>
                  <td className='mx-auto float-end'>

                    <div>
                      <Calender setLabel={(date) => setLabel(date, 'estimate_date')} selectedDate={type === 'edit' ? formData.estimate_date : ''} calenderlabel="EstimateDate" />
                    </div>
                    {/* <div>
                      <TextInputForm placeholder={'Bill Number'}
                        labelname={'Bill Number'}
                        name='bill_no'
                        value={type === 'edit' ? formData.bill_no : formData.bill_no}
                        onChange={(e) => handleChange(e, 'bill_no')} />
                    </div>
                    <div> <TextInputForm labelname={<>Vehicle No: </>} /> </div> */}
                  </td>
                </tr>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='main my-3'>
        <div>
          <Container fluid className='px-0'>
            <Row>
              <Col lg='12' className='py-3'>
                <div>No Of Products : <span>{formData.product.length}</span></div>
              </Col>
              <Col lg='12'>
                <div className='py-2 category-table bill'>
                  <Table>
                    <thead className=''>
                      <tr>
                        <th rowSpan={2}>#</th>
                        <th rowSpan={2} className='wrdbrk w-20'>Product Name</th>
                        <th rowSpan={2} className='wrdbrk w-7'>Qty</th>
                        <th rowSpan={2} className='wrdbrk w-10'>Unit</th>
                        <th rowSpan={2} className='wrdbrk w-7'>Price / Unit</th>
                        <th colSpan={2} className='wrdbrk w-15'>Discount</th>
                        <th colSpan={2} className='wrdbrk w-15' >Tax</th>
                        <th rowSpan={2} className='wrdbrk'>Amount</th>
                      </tr>
                      <tr>
                        <th>%</th>
                        <th className='w-10'>Amount</th>
                        <th >%</th>
                        <th className='w-10'>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.product && formData.product.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>

                            <div>
                              <DropDownUI
                                optionlist={productData.map(user => ({
                                  value: user.product_id,
                                  label: user.product_name
                                }))}
                                placeholder='ProductName'
                                name='product_id'
                                value={row.product_id}
                                onChange={(updatedFormData) => setFormData({ ...formData, product: formData.product.map((r, i) => i === index ? { ...r, product_id: updatedFormData.product_id } : r) })}
                                onKeyDown={(e) => handleKeyPress(e, index)}
                                autoFocus={index === formData.product.length - 1}
                              />
                            </div>
                            <div>
                              <TextInputForm placeholder={"Remarks"} />
                            </div>
                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'qty'}
                              name='qty'
                              value={row.qty}
                              onChange={(e) => handleChange(e, 'qty', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                            ></TextInputForm>
                          </td>
                          <td>
                            <DropDownUI
                              optionlist={productData.find(product => product.product_id === row.product_id)?.unit_array.map(unit => ({
                                value: unit.unit_id,
                                label: unit.unit_name
                              })) || []}
                              placeholder='Unit'
                              name='unit'
                              value={row.unit}
                              onChange={(updatedFormData) => setFormData({ ...formData, product: formData.product.map((r, i) => i === index ? { ...r, unit: updatedFormData.unit } : r) })}
                              onKeyDown={(e) => handleKeyPress(e, index)} />
                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'price'}
                              name='price'
                              value={row.price_unit}
                              onChange={(e) => handleChange(e, 'price_unit', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                            />

                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'discount_percentage'}
                              name='discount_percentage'
                              value={row.discount_percentage}
                              onChange={(e) => handleChange(e, 'discount_percentage', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                              disabled={formData.discount_all}
                            />
                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'discount_amt'}
                              name='discount_amt'
                              value={row.discount_amt}
                              onChange={(e) => handleChange(e, 'discount_amt', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                              disabled={formData.total_discountall}
                            />
                          </td>

                          <td className='w-10 text-left'>
                            <DropDownUI
                              optionlist={alltax}
                              placeholder='Tax'
                              name='tax_percentage'
                              value={row.tax_percentage}
                              onChange={(updatedFormData) => {
                                const selectedTax = alltax.find(tax => tax.value === updatedFormData.tax_percentage);
                                console.log('selecedtax', selectedTax);
                                const taxType = selectedTax ? selectedTax.value.split('@')[0] : '';
                                console.log('taxtype', taxType);
                                settax_type(taxType);
                                setFormData(prevFormData => ({
                                  ...prevFormData,
                                  product: prevFormData.product.map((r, i) => i === index ? { ...r, tax_percentage: updatedFormData.tax_percentage } : r)
                                }));
                                calculateTax(formData.product[index], updatedFormData.tax_percentage, index, taxType);
                                handleChange({ value: parseFloat(updatedFormData.tax_percentage.match(/\d+\.*\d*/)[0]) }, 'tax_percentage', index);
                                //handleChange({ value: updatedFormData.taxType}, 'tax_type', index);
                              }}
                              onKeyDown={(e) => handleKeyPress(e, index)}

                            />
                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'tax_amt'}
                              name='tax_amt'
                              value={row.tax_amt}
                              onChange={(e) => handleChange(e, 'tax_amt', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}

                            />
                          </td>
                          <td>
                            <TextInputForm
                              placeholder={'amt'}
                              name='total_amt'
                              value={row.total_amt}
                              onChange={(e) => handleChange(e, 'total_amt', index)}
                              onKeyDown={(e) => handleKeyPress(e, index)}
                            />
                          </td>
                          <td className='d-none' >
                            <div className='d-none'>
                              {index === formData.product.length - 1 && (
                                <ClickButton label={<>Add More</>} onClick={handleAddRow} />
                              )}
                            </div>
                          </td>
                          <td>
                            <Delete onClick={() => handleDeleteRow(index)} label={<MdDeleteForever />}></Delete>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='my-3'>
          <Container>
            <Row>
              <Col lg='5'>
                {/* <PayMents /> */}
              </Col>
              <Col lg='7'>
                <div className='inside my-3'>
                  <Table>
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td className='w-25'>
                        <TextInputForm
                          placeholder={'Total'}
                          name='total'
                          value={type === 'edit' ? formData.total : formData.total}
                          onChange={handlePaidChange}
                        />

                      </td>
                    </tr>


                    <tr>

                    </tr>
                    <tr>
                      <td>Paid</td>
                      <td></td>
                      <td className='w-25'>
                        <TextInputForm
                          placeholder={'Paid'}
                          name='paid'

                          value={type === 'edit' ? formData.paid : formData.paid}
                          onChange={handlePaidChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Balance</td>
                      <td></td>
                      <td className='w-25'>
                        <TextInputForm
                          placeholder={'balance'}
                          name='balance_amount'
                          value={type === 'edit' ? formData.balance_amount : formData.balance_amount}
                          onChange={(e) => handleChange(e, 'balance_amount')}
                        />
                      </td>
                    </tr>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='main stick-bottom'>
        <div className='w-50 ms-auto'>
          <Container>
            <Row>
              <Col lg="12">
                <div className='text-center'>
                  {type === 'edit' ? (
                    <>
                      <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <span className='mx-2'>
                        <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                      </span>

                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/sales/estimation")}></ClickButton>
                      </span>
                    </>

                  ) : (
                    <>
                      <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <span className='mx-2'>
                        <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>

                      </span>

                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/sales/estimation")}></ClickButton>
                      </span>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Modal show={showModal} onHide={handleCloseModal} size='lg'>
            <Modal.Header >
              <PageHead pagehead={<>Party Creation</>} onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col lg="4" className='py-3'>
                    <TextInputForm placeholder={'Company Name'}
                      labelname={'Company Name'}
                      name='company_name'
                      value={formDataPartySales.company_name}
                      onChange={(e) => handleChangePartySales(e, 'company_name')} />
                  </Col>
                  <Col lg="4" className='py-3'>
                    <TextInputForm placeholder={'Party Name'}
                      labelname={'Party Name'}
                      name='party_name'
                      value={formDataPartySales.party_name}
                      onChange={(e) => handleChangePartySales(e, 'party_name')} />
                  </Col>
                  <Col lg="4" className='py-3'>
                    <TextInputForm placeholder={'Mobile Number'}
                      labelname={'Mobile Number'}
                      name='mobile_number'
                      value={formDataPartySales.mobile_number}
                      onChange={(e) => handleChangePartySales(e, 'mobile_number')} />
                  </Col>
                  <Col lg="4" className='py-3'>
                    <TextInputForm placeholder={'GST NO'}
                      labelname={'GST NO'}
                      name='gst_no'
                      value={formDataPartySales.gst_no}
                      onChange={(e) => handleChangePartySales(e, 'gst_no')} />
                  </Col>
                </Row>
              </Container>
              <Tabs
                defaultActiveKey="address"
                className="mb-3"
              >
                <Tab eventKey="address" title="Address">
                  <Container>
                    <Row>
                      <Col lg="6" className='py-3'>
                        <TextInputForm placeholder={'Alter Number'}
                          labelname={'Alter Number'}
                          name='alter_number'
                          value={formDataPartySales.alter_number}
                          onChange={(e) => handleChangePartySales(e, 'alter_number')} />
                      </Col>
                      <Col lg="6" className='py-3'>
                        <TextInputForm
                          placeholder={'E Mail'}
                          labelname={'E Mail'}
                          name='email '
                          value={formDataPartySales.email}
                          onChange={(e) => handleChangePartySales(e, 'email')} />
                      </Col>
                      <Col lg='6' className='py-3'>
                        <label className='pb-2'>Billing Address</label>
                        <textarea
                          className='form-cntrl form-control'
                          placeholder={'Address'}
                          labelname={'Address'}
                          name='billing_address '
                          value={formDataPartySales.billing_address}
                          onChange={(e) => handleChangePartySales(e, 'billing_address')} />
                      </Col>
                      <Col lg='6' className='py-3'>
                        <label className='pb-2'>Shipping Address</label>
                        <textarea className='form-cntrl form-control'
                          placeholder={'Address'}
                          labelname={'Address'}
                          name='shipp_address'
                          value={formDataPartySales.shipp_address}
                          onChange={(e) => handleChangePartySales(e, 'shipp_address')} />
                      </Col>
                    </Row>
                  </Container>
                </Tab>
                <Tab eventKey="credit-balance" title="Credit & Balance">
                  <Container>
                    <Row>
                      <Col lg="4" className='py-3'>
                        <div className='w-100'>
                          <Calender setLabel={(date) => setLabels(date, 'opening_date')} selectedDate={formDataPartySales.opening_date} calenderlabel="Opening Date" />

                        </div>
                      </Col>
                      <Col lg="4" className='py-3'>
                        <div className='w-100'>
                          <DropDownUI
                            optionlist={SelectDrCr}
                            placeholder='Select DR or CR'
                            labelname={<> Select DR or CR </>}
                            name='ac_type'
                            value={formDataPartySales.ac_type}
                            onChange={(updatedformDataPartySales) => setformDataPartySales({ ...formDataPartySales, ac_type: updatedformDataPartySales.ac_type })}
                          />
                        </div>
                      </Col>
                      <Col lg="4" className='py-3'>
                        <TextInputForm labelname={"Opening Balance"}
                          placeholder={'Opening Balance'}
                          name='opening_balance'
                          value={formDataPartySales.opening_balance}
                          onChange={(e) => handleChangePartySales(e, 'opening_balance')} />
                      </Col>
                    </Row>
                  </Container>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <ClickButton label={<>Cancel</>} onClick={handleCloseModal} >
                Close
              </ClickButton>
              <ClickButton label={<>Submit</>} onClick={SavePartySales}   >
                Save Changes
              </ClickButton>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  )
}

export { SalesInvoiceEntry, EstimationEntry } 