import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar, Row, Col, Button, Modal, ModalBody } from 'react-bootstrap'
import { useNavigate, Navigate } from 'react-router-dom';
import { Buttons } from '../components/Buttons'
import { TableUI } from '../components/Tables'
// import { FaEquals, FaPlus } from "react-icons/fa6";
import { PageHead, PageTitle } from '../components/PageTitle';
import '../components/Table.css'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdOutlineClose } from "react-icons/md";
import moment from 'moment/moment'
import { Calender, DropDownUI, TextInputForm } from '../components/Forms';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Pdf from './Pdf';
import Pagnation from '../components/Pagnation';
const ProductHistory = ["S.No", "Date", "Bill No.", "Party Name", "Amount", "Status"]


const PurchaseBill = () => {
    const [openFilter, setFilter] = useState(false);
    const closeFilter = () => setFilter(false);
    const showFilter = () => setFilter(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState('');
    const [printrow, setPrintRow] = useState([]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    const [filterData, setFilterData] = useState({
        search_text: '',
        party_id: '',
        from_date: '',
        to_date: ''
    })
    console.log('kanna', filterData);
    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;
        setFilterData({
            ...filterData,
            [fieldName]: value
        });
    };
    const setLabel = (value, field) => {
        setFilterData({
            ...filterData,
            [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
        });
    };
    const clearFilter = () => {
        setFilterData({
            search_text: '',
            party_id: '',
            from_date: '',
            to_date: ''
        })
    }
    const [crtPurchase, setPurchase] = useState([])
    const fetchDataPurchase = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://node.zentexus.com/purchaseparty/list/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: ''
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            console.log('siva', responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setPurchase(responseData.data);
                console.log(setUserData)

            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };
    useEffect(() => {
        fetchDataPurchase(); // Call fetchData directly in useEffect
    }, []);
    const [userData, setUserData] = useState([]);
    console.log('kannan', userData)
    const fetchData = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch(' https://node.zentexus.com/purchasebill/list/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: filterData.search_text,
                    party_id: filterData.party_id,
                    from_date: filterData.from_date,
                    to_date: filterData.to_date
                })
            });
            console.log('json', JSON.stringify({
                search_text: filterData.search_text,
                party_id: filterData.party_id,
                from_date: filterData.from_date,
                to_date: filterData.to_date
            }))
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            console.log(responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setUserData(responseData.data);
                console.log(setUserData)

            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };
    useEffect(() => {


        fetchData(); // Call fetchData directly in useEffect

    }, [filterData]);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    useEffect(() => {
        const checkSession = async () => {
            try {
                // Check session logic here, e.g., fetch from local storage or server
                const session = localStorage.getItem('session');
                if (!session) {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Error checking session:', error.message);
                setIsLoggedIn(false);
            }
        };
        checkSession();
    }, []);
    if (!isLoggedIn) {

        return <Navigate to="/login" replace />;
    }
    return (
        <div >
            <div>
                <Container fluid className='px-1'>
                    <div className='main p-3 my-2'>
                        <div>
                            <Navbar >
                                <Navbar.Brand><PageTitle PageTitle={"Purchase Bill"} /></Navbar.Brand>
                                <Nav className="ms-auto">
                                    {/* <span className='mx-2 py-2'> <Buttons lable={<>PDF</>} /></span> */}
                                    {/* <PDFDownloadLink document={<Pdf/>} fileName='FORM '>
                                        {
                                            ({loading})=> loading ? ( <span className='mx-2 py-2'> <Buttons lable={<>Loading Document</>}  /></span>) : ( <span className='mx-2 py-2'> <Buttons lable={<>PDF</>}  /></span>)
                                        }
                                    </PDFDownloadLink> */}
                                    <span className='mx-2 py-2'><Buttons lable={<>Filter</>} onClick={showFilter} /></span>
                                </Nav>
                            </Navbar>
                        </div>
                        <Offcanvas show={openFilter} onHide={closeFilter} placement='end'>
                            <Offcanvas.Body>
                                <Row>
                                    <Col lg="6">
                                        <PageTitle PageTitle={<>Purchase Bill</>} />
                                    </Col>
                                    <Col lg="6" className='align-self-center'>
                                        <div className='text-end'>
                                            <Buttons onClick={closeFilter} lable={<><MdOutlineClose /></>}></Buttons>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <div className='py-3'>

                                        <TextInputForm
                                            labelname={'Search Receipt'}
                                            placeholder={'Search Receipt'}
                                            name='search_text'
                                            value={filterData.search_text}
                                            onChange={(e) => handleChange(e, 'search_text')}
                                        ></TextInputForm>
                                    </div>
                                    <div className='py-3'>

                                        <DropDownUI
                                            optionlist={crtPurchase.map(user => ({
                                                value: user.party_id,
                                                label: user.party_name
                                            }))}
                                            placeholder='PartyName'
                                            labelname='PartyName'
                                            name='party_id'
                                            value={filterData.party_id}
                                            onChange={(updatedFormData) => setFilterData({ ...filterData, party_id: updatedFormData.party_id })}
                                        />
                                    </div>
                                    <Row>
                                        <Col lg="6" className='align-self-center'>
                                            <div>
                                                <Calender setLabel={(date) => setLabel(date, 'from_date')} selectedDate={filterData.from_date} calenderlabel="From Date" />
                                            </div>
                                        </Col>
                                        <Col lg="6" className='align-self-center'>
                                            <div>
                                                <Calender setLabel={(date) => setLabel(date, 'to_date')} selectedDate={filterData.to_date} calenderlabel="To Date" />
                                            </div>
                                        </Col>

                                        <Col lg="12" className='align-self-center'>
                                            <div className='my-5 text-center'>
                                                <Button lable={"Clear"} onClick={clearFilter}></Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                        {/* <div className='d-lg-flex align-items-center'>
                            <div className='paid box'>
                                <div className='title-total'>Paid</div>
                                <div className='maths-total'> ₹ <span> 1890</span></div>
                            </div>
                            <div className='mx-2 text-center'><FaPlus /></div>
                            <div className='unpaid box'>
                                <div className='title-total'>UnPaid</div>
                                <div className='maths-total'> ₹ <span> 1890</span></div>
                            </div>
                            <div className='mx-2 text-center'><FaEquals /></div>
                            <div className='total box'>
                                <div className='title-total'>Total</div>
                                <div className='maths-total'> ₹ <span> 1890</span></div>
                            </div>
                        </div> */}
                    </div>
                    <div className='main p-3'>
                        <div className='py-1'>
                            <Navbar >
                                <Navbar.Brand><PageTitle PageTitle={"Transactions"} /></Navbar.Brand>
                                <Nav className="ms-auto">
                                    <span className='mx-2 py-2'> <Buttons lable={<>Add New</>} onClick={() => navigate("/console/purchase/bill/create")} /></span>
                                </Nav>
                            </Navbar>
                        </div>
                        <div className='py-2 c-table table-scroll' >
                            <TableUI headers={ProductHistory} body={userData} type='purchasebill' onDelete={fetchData} pageview={"yes"}></TableUI>
                        </div>
                    </div>
                </Container>

            </div>

        </div>
    )
}

export default PurchaseBill