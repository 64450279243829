import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal, Tab, Tabs, Offcanvas } from 'react-bootstrap';
import { Buttons, ClickButton } from '../components/Buttons';
import { TableUI } from '../components/Tables'
import { PageHead, PageTitle } from '../components/PageTitle';
import { MdOutlineClose } from "react-icons/md";
import { Calender, DropDown, DropDownUI, TextInputForm } from '../components/Forms';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment/moment'
import 'react-toastify/dist/ReactToastify.css';
import { FaMagnifyingGlass } from "react-icons/fa6";
import Pagnation from '../components/Pagnation';
const ProductHistory = ["S.No", "Type", "Date", "Receipt No", "amount"]
const DummyTablehead = ["Name", 'Amount', "Status"]
const DropList = [
  {
    value: 'DR',
    label: 'DR'
  },
  {
    value: 'CR',
    label: 'CR'
  }
];
const Party = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [Limit, SetLimit] = useState();
  const [show, setShow] = useState(false);
  const [openFilter, setFilter] = useState(false);
  const handleClose = () => {
    setShow(false)
    location.state = [];
  };
  const handleShow = () => {
    setCrtSales(null)
    setFormData({
      party_id: '',
      party_name: '',
      mobile_number: '',
      alter_number: '',
      email: '',
      state: '',
      city: '',
      company_name: '',
      gst_no: '',
      billing_address: '',
      shipp_address: '',
      opening_balance: '',
      opening_date: '',
      ac_type: ''
    })
    setShow(true);
  }
  const closeFilter = () => setFilter(false);
  const showFilter = () => setFilter(true);
  const [clickedData, setClickedData] = useState(null);
  const handleClick = (data) => {
    setClickedData(data);
  };
  const [crtSales, setCrtSales] = useState(null);
  const handleEditClick = (rowdata) => {
    setCrtSales(rowdata)
    setShow(true)

  };
  const [formData, setFormData] = useState({
    party_id: '',
    party_name: '',
    mobile_number: '',
    alter_number: '',
    email: '',
    city: '',
    state: '',
    company_name: '',
    gst_no: '',
    billing_address: '',
    shipp_address: '',
    opening_balance: '',
    opening_date: '',
    ac_type: ''
  });
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtSales != null) {
      setCrtSales({
        ...crtSales,
        [fieldName]: value
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: value
      });
    }
  };

  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
    });
  };
  const [searchText, setSearchText] = useState('');
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
  };
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  console.log('userData', userData);
  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/salesparty/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: searchText
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData)
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(setUserData)
        if (responseData.data.length > 0) {
          setClickedData(responseData.data[0]);
        }
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, [searchText]);
  const [showAlert, setShowAlert] = useState(false);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",

    });
  }
  const handleSubmit = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/salesparty/create/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      console.log(formData);
      const responseData = await response.json();

      console.log(responseData);

      if (responseData.status === 200) {
        fetchData();
        successAlert(responseData.msg)
        setTimeout(() => {
          handleClose(); // Close modal after 10 seconds
        }, 2000);
      }
      else if (responseData.status === 400) {
        console.log('responseData', responseData.msg)
        toast.error('Missing required fields!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/salesparty/update/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ // Include the company ID in the request
          party_id: crtSales.party_id,
          party_name: crtSales.party_name,
          mobile_number: crtSales.mobile_number,
          alter_number: crtSales.alter_number,
          email: crtSales.email,
          city: crtSales.city,
          state: crtSales.state,
          company_name: crtSales.company_name,
          gst_no: crtSales.gst_no,
          billing_address: crtSales.billing_address,
          shipp_address: crtSales.shipp_address,
          opening_balance: crtSales.opening_balance,
          opening_date: crtSales.opening_date,
          ac_type: crtSales.ac_type,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update company');
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        fetchData();
        toast.success('Sales Party updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          handleClose();
        }, 2000);

        // Navigate to the user list page after a delay

      } else {
        console.error(responseData.msg || 'Unknown error occurred during update');
      }
    } catch (error) {
      console.error('Error updating product:', error.msg);
    }

    setLoading(false);
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg="4" className='py-1'>
            <div className='main my-2'>
              <Row>
                <Col lg="7">
                  <div className='mx-2'>
                    {/* <Buttons lable={<>Filter</>} onClick={showFilter} /> */}
                    <TextInputForm placeholder={"Name"}
                      onChange={(e) => handleSearch(e.target.value)}
                      prefix_icon={<FaMagnifyingGlass />}
                      labelname={"Search"}> </TextInputForm>
                  </div>
                </Col>
                <Col lg="5" className='align-item-baseline d-flex justify-content-end'>
                  <div ><Buttons lable={<> Add New</>} onClick={handleShow} /></div>
                </Col>
              </Row>

              <div className='py-2 c-table'>
                <TableUI headers={DummyTablehead} body={userData} type="partysales" handleEditClick={handleEditClick} onClick={handleClick} onDelete={fetchData} />
              </div>
            </div>
          </Col>
          <Col lg='8'>
            <div className='my-2'>
              <div className='main'>
                {clickedData && (
                  <div >
                    <div className='d-flex justify-content-between mb-2'>
                      <div>Name : {clickedData.party_name}</div>
                      <div><span>MobileNumber : {clickedData.mobile_number}</span></div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <div><span>AccountType : {clickedData.ac_type}</span></div>
                      <div><span>Amount : {clickedData.opening_balance}</span></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className='my-2'>
              <div className='main'>
                <div className='d-flex justify-content-between'>
                  <div>
                    <div className='purchaseparty-name py-2'>Siva</div>
                    <div className='sale-price py-2'> Phone No : <span>99943 07256</span></div>
                    <div className='credit-limit py-2'> Credit Limit : <span>set credit Limit</span></div>
                  </div>
                  <div className='text-end'>

                    <div className='address py-2'> Address : <span>Add Address</span></div>
                    <div className='gst py-2'> Stock Value : <span>Add GST</span></div>
                  </div>
                </div>
              </div>
            </div>  */}
            <div className='my-2'>
              <div className='main'>
                <Row>
                  <Col lg='12'>
                    <PageTitle PageTitle={<>Transactions</>} />
                  </Col>
                </Row>
                <Offcanvas show={openFilter} onHide={closeFilter} placement='end'>
                  <Offcanvas.Body>
                    <Row>
                      <Col lg="6">
                        <PageTitle PageTitle={<>Sale Party</>} />
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div className='text-end'>
                          <Buttons onClick={closeFilter} lable={<><MdOutlineClose /></>}></Buttons>
                        </div>
                      </Col>
                    </Row>
                    <div className='py-3'>
                      <TextInputForm labelname={<>Invoice No.</>} />
                    </div>
                    <div className='py-3'>
                      <DropDownUI labelname={"Select Sale Party"} />
                    </div>
                    <div className='py-3'>
                      <DropDownUI labelname={" Select Type"} />
                    </div>
                    <Row>
                      <Col lg="6" className='align-self-center'>
                        <div>
                          <Calender labelname={<>From</>} />
                        </div>
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div>
                          <Calender labelname={<>To</>} />
                        </div>
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div className='my-5 text-center'>
                          <Buttons lable={"Apply"}></Buttons>
                        </div>
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div className='my-5 text-center'>
                          <Buttons lable={"Clear"}></Buttons>
                        </div>
                      </Col>
                    </Row>
                  </Offcanvas.Body>
                </Offcanvas>
                <div className='py-2 c-table table-scroll'>
                  <TableUI headers={ProductHistory} body={clickedData != null ? clickedData.transactions : []} type="partysalestransactions" pageview={"yes"}></TableUI></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          size='lg'
        >
          <Modal.Header>
            <PageHead pagehead={<>Sales Party</>} onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg="4" className='py-3'>
                  <TextInputForm placeholder={'Company Name'}
                    labelname={'Company Name'}
                    name='company_name'
                    value={crtSales != null ? crtSales.company_name : formData.company_name}
                    onChange={(e) => handleChange(e, 'company_name')} />
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm placeholder={'Party Name'}
                    labelname={'Party Name'}
                    name='party_name'
                    value={crtSales != null ? crtSales.party_name : formData.party_name}
                    onChange={(e) => handleChange(e, 'party_name')} />
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm placeholder={'Mobile Number'}
                    labelname={'Mobile Number'}
                    name='mobile_number'
                    value={crtSales != null ? crtSales.mobile_number : formData.mobile_number}
                    onChange={(e) => handleChange(e, 'mobile_number')} />
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm placeholder={'GST NO'}
                    labelname={'GST NO'}
                    name='gst_no'
                    value={crtSales != null ? crtSales.gst_no : formData.gst_no}
                    onChange={(e) => handleChange(e, 'gst_no')} />
                </Col>
              </Row>
            </Container>
            <Tabs
              defaultActiveKey="address"
              className="mb-3"
            >
              <Tab eventKey="address" title="Address">
                <Container>
                  <Row>
                    <Col lg="6" className='py-3'>
                      <TextInputForm placeholder={'Alter Number'}
                        labelname={'Alter Number'}
                        name='alter_number'
                        value={crtSales != null ? crtSales.alter_number : formData.alter_number}
                        onChange={(e) => handleChange(e, 'alter_number')} />
                    </Col>
                    <Col lg="6" className='py-3'>
                      <TextInputForm
                        placeholder={'E Mail'}
                        labelname={'E Mail'}
                        name='email '
                        value={crtSales != null ? crtSales.email : formData.email}
                        onChange={(e) => handleChange(e, 'email')} />
                    </Col>
                    <Col lg='6' className='py-3'>
                      <label className='pb-2'>Billing Address</label>
                      <textarea
                        className='form-cntrl form-control'
                        placeholder={'Address'}
                        labelname={'Address'}
                        name='billing_address '
                        value={crtSales != null ? crtSales.billing_address : formData.billing_address}
                        onChange={(e) => handleChange(e, 'billing_address')} />
                    </Col>
                    <Col lg='6' className='py-3'>
                      <label className='pb-2'>City</label>
                      <textarea
                        className='form-cntrl form-control'
                        placeholder={'City'}
                        labelname={'Address'}
                        name='city '
                        value={crtSales != null ? crtSales.city : formData.city}
                        onChange={(e) => handleChange(e, 'city')} />
                    </Col>
                    <Col lg='6' className='py-3'>
                      <label className='pb-2'>State</label>
                      <textarea
                        className='form-cntrl form-control'
                        placeholder={'Sate'}
                        labelname={'Address'}
                        name='state '
                        value={crtSales != null ? crtSales.state : formData.state}
                        onChange={(e) => handleChange(e, 'state')} />
                    </Col>
                    <Col lg='6' className='py-3'>
                      <label className='pb-2'>Shipping Address</label>
                      <textarea className='form-cntrl form-control'
                        placeholder={'Address'}
                        labelname={'Address'}
                        name='shipp_address'
                        value={crtSales != null ? crtSales.shipp_address : formData.shipp_address}
                        onChange={(e) => handleChange(e, 'shipp_address')} />
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="credit-balance" title="Credit & Balance">
                <Container>
                  <Row>
                    <Col lg="4" className='py-3'>
                      <div className='w-100'>
                        <Calender setLabel={(date) => setLabel(date, 'opening_date')} selectedDate={crtSales != null ? crtSales.opening_date : formData.opening_date} calenderlabel="OpeaningDate" />
                      </div>
                    </Col>
                    <Col lg="4" className='py-3'>
                      <div className='w-100'>
                        <DropDownUI
                          optionlist={DropList}
                          placeholder='Select DR or CR'
                          labelname={<> Select DR or CR </>}
                          name='ac_type'
                          value={crtSales != null ? crtSales.ac_type : formData.ac_type}
                          onChange={(updatedFormData) => {
                            if (crtSales != null) {
                              setCrtSales({ ...crtSales, ac_type: updatedFormData.ac_type })
                            } else {
                              setFormData({ ...formData, ac_type: updatedFormData.ac_type })
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="4" className='py-3'>
                      <TextInputForm labelname={"Opening Balance"}
                        placeholder={'Opening Balance'}
                        name='opening_balance'
                        value={crtSales != null ? crtSales.opening_balance : formData.opening_balance}
                        onChange={(e) => handleChange(e, 'opening_balance')} />
                    </Col>

                    {/* <Col lg='12' className='py-3'>
                      <PageTitle PageTitle={"Credit Limit"} />
                      <Row>
                        <Col lg='3' className='py-3 align-self-center'>
                          <div className='text-center'>No Limit</div>
                        </Col>
                        <Col lg='3' className='py-3 align-self-center'>
                          <div>
                            <Buttons type="button" onClick={() => SetLimit(!Limit)} lable={Limit === false ? 'Set Limit' : 'No Limit'} />
                          </div>
                        </Col>
                        <Col lg='6' className='py-3 align-self-center'>
                          <div >
                            {Limit && <div>
                              <div> </div>
                              <TextInputForm labelname={"Limit"} />
                            </div>}
                          </div>
                        </Col>
                      </Row>
                    </Col> */}
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <div className='text-center py-4'>
              {crtSales != null ? (
                <>
                  <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <span className='mx-2'>
                    <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                  </span>

                  <span className='mx-2'>
                    <ClickButton label={<>Cancel</>} onClick={() => { handleClose() }}></ClickButton>
                  </span>
                </>

              ) : (
                <>
                  <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <span className='mx-2'>
                    <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
                  </span>

                  <span className='mx-2'>
                    <ClickButton label={<>Cancel</>} onClick={() => { handleClose() }}></ClickButton>
                  </span>
                </>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  )
}

export default Party