import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Nav, Table, Navbar, } from 'react-bootstrap'
import { PageTitle } from '../components/PageTitle';
import { Buttons } from '../components/Buttons'
import { useNavigate, Navigate } from 'react-router-dom';
import { ProductTable } from '../components/Tables'
import SalesInvoice from './SalesInvoice';
import PurchaseBilllisting from './PurchaseBilllisting'
import { FaEquals, FaPlus, FaMagnifyingGlass } from "react-icons/fa6";
import '../components/Table.css'
import { Calender, DropDown, DropDownUI, TextInputForm } from '../components/Forms';
import { PDFDownloadLink } from '@react-pdf/renderer';
import SaleRep from '../pages/SaleRep';
import PurchaseReport from './PurchaseReport';
import StockPDF from './StockPDF';
import moment from 'moment';
const DropList = [
  {
    value: 'Inward',
    label: 'Inward'
  },
  {
    value: 'OutWard',
    label: 'OutWard'
  }
]
const Reports = () => {

  // State to manage the active tab
  const [activeTab, setActiveTab] = useState('Stock');
  const navigate = useNavigate();
  // Function to handle tab click
  const initialFormData = {
    party_name: '',
    party_id: '',
    fromdate: '',
    todate: ''
  };
  const [formData, setFormData] = useState({ ...initialFormData });
 

 
  const setDateOfBirth = (date) => {
    setFormData(prevData => ({
      ...prevData,
      date: date,
    }));
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: value // Update the specified field in formData with the provided value
    });
  };
  const [loading, setLoading] = useState(true);
  const [userDataSalesParty, setUserDataSalesPart] = useState([]);
  
  const [stockData, setstockData] = useState([]);
  

  const fetchDataSalesParty = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/salesparty/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      
      setLoading(false);
      if (responseData.status === 200) {
        let sortedData = responseData.data;
        setUserDataSalesPart(sortedData);


      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  

  const fetchSalesInvoiceList = async () => {
    try {
      setLoading(true);
      const companyId = localStorage.getItem('companyId');
      const response = await fetch(`https://node.zentexus.com/saleinvoice/list/${companyId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });
  
      setLoading(false);
  
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
  
      const responseData = await response.json();
  
      // Logging the entire response data for debugging
      console.log('Response Data:', responseData);
  
      if (responseData && responseData.data) {
        let sortedData = responseData.data;
        console.log('sortedData', sortedData);
  
        if (formData.party_id) {
          sortedData = sortedData.filter(user => user.party_id === formData.party_id);
        }
        if (formData.fromdate) {
          const fromDate = moment(formData.fromdate, 'DD-MM-YYYY');
          sortedData = sortedData.filter(user => {
            const createDate = moment(user.bill_date, 'DD-MM-YYYY');
            return createDate.isSameOrAfter(fromDate);
          });
        }
        console.log("formData.todate",sortedData[0].bill_date);
  
        if (formData.todate) {
          const toDate = moment(formData.todate, 'DD-MM-YYYY');
          sortedData = sortedData.filter(user => {
            const createDate = moment(user.bill_date, 'DD-MM-YYYY');
            return createDate.isSameOrBefore(toDate);
          });
        }
  
        setstockData(sortedData);
  
      } else {
        throw new Error(responseData.message ? responseData.message : 'Unknown error');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataSalesParty();
    fetchSalesInvoiceList(); // Call fetchData directly in useEffect
  }, [formData]);
  const [salesInvoiceList, setSalesInvoice] = useState([]);
 
 
  ///////////////////SalesReportOver///////////////////////////////


  const initialFormDataPurchaseParty = {
    party_name: '',
    party_id: '',
    fromdate: '',
    todate: ''
  };
  const [formDataPurchaseParty, setFormDataPurchaseParty] = useState({ ...initialFormDataPurchaseParty });
 
  const setDateOfBirths = (date) => {
    setFormDataPurchaseParty(prevData => ({
      ...prevData,
      date: date,
    }));
  };
  const setLabell = (value, field) => {
    setFormDataPurchaseParty({
      ...formDataPurchaseParty,
      [field]: value // Update the specified field in formData with the provided value
    });
  };
  const [userDataPurchaseParty, setUserDataPurchaseParty] = useState([]);
  const [stockDataPurchase, setstockDataPurchase] = useState([]);
  const fetchDataPurchaseParty = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/purchaseparty/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      
      setLoading(false);
      if (responseData.status === 200) {
        let sortedData = responseData.data;
        setUserDataPurchaseParty(sortedData);

      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  // const fetchPurchaseList = async () => {
  //   try {
  //     setLoading(true);
  //     const companyId = localStorage.getItem('companyId');
  //     const response = await fetch('https://node.zentexus.com/purchasebill/list/' + companyId, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         search_text: ''
  //       })
  //     });
  //     setLoading(false)
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch data');
  //     }

  //     const responseData = await response.json();
  //     // Logging the entire response data for debugging
      
  //     if (responseData) {
  //       let sortedData = responseData.data;

       
  //       if (formDataPurchaseParty.party_id) {
         
  //         sortedData = sortedData.filter(user => user.party_id === formDataPurchaseParty.party_id);
  //       }

  //       if (formDataPurchaseParty.fromdate && formData.todate) {
  //         // Convert string dates to Date objects
  //         const fromDate = new Date(formDataPurchaseParty.fromdate);
  //         const toDate = new Date(formDataPurchaseParty.todate);

  //         // Filter data within the date range
  //         sortedData = sortedData.filter(user => {
  //           const createDate = new Date(user.bill_date);
  //           return createDate >= new Date(fromDate) && createDate <= new Date(toDate);
  //         });
  //       } else if (formDataPurchaseParty.fromdate) {
  //         // Filter data from the fromDate onwards
  //         const fromDate = new Date(formDataPurchaseParty.fromdate);
  //         sortedData = sortedData.filter(user => {
  //           const createDate = new Date(user.bill_date);
  //           return createDate >= new Date(fromDate);
  //         });
  //       } else if (formDataPurchaseParty.todate) {
  //         // Filter data up to the toDate
  //         const toDate = new Date(formDataPurchaseParty.todate);
  //         sortedData = sortedData.filter(user => {
  //           const createDate = new Date(user.bill_date);
  //           return createDate <= new Date(toDate);
  //         });
  //       }

  //       setstockDataPurchase(sortedData);

  //     } else {
  //       throw new Error(responseData.message ? responseData.message : 'Unknown error');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error.message);
  //     setLoading(false)
  //   }
  // };
  
  const fetchPurchaseList = async () => {
    try {
      setLoading(true);
      const companyId = localStorage.getItem('companyId');
      const response = await fetch(`https://node.zentexus.com/purchasebill/list/${companyId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });
  
      setLoading(false);
  
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
  
      const responseData = await response.json();
  
      // Logging the entire response data for debugging
      console.log('Response Data:', responseData);
  
      if (responseData && responseData.data) {
        let sortedData = responseData.data;
        console.log('sortedData', sortedData);
  
        if (formDataPurchaseParty.party_id) {
          sortedData = sortedData.filter(user => user.party_id === formData.party_id);
        }
        if (formDataPurchaseParty.fromdate) {
          const fromDate = moment(formDataPurchaseParty.fromdate, 'DD-MM-YYYY');
          sortedData = sortedData.filter(user => {
            const createDate = moment(user.bill_date, 'DD-MM-YYYY');
            return createDate.isSameOrAfter(fromDate);
          });
        }
        console.log("formDataPurchaseParty.todate",sortedData[0].bill_date);
  
        if (formDataPurchaseParty.todate) {
          const toDate = moment(formDataPurchaseParty.todate, 'DD-MM-YYYY');
          sortedData = sortedData.filter(user => {
            const createDate = moment(user.bill_date, 'DD-MM-YYYY');
            return createDate.isSameOrBefore(toDate);
          });
        }
  
        setstockDataPurchase(sortedData);
  
      } else {
        throw new Error(responseData.message ? responseData.message : 'Unknown error');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataPurchaseParty();
    fetchPurchaseList();
  }, [formDataPurchaseParty]);


  //////////////////////////////purchaseReportover/////////////////////////////////////////

  const initialFormDataProduct = {
    product_name: '',
    product_id: '',
    inout: '',
    fromdate: '',
    todate: ''
  };
  const [formDataProduct, setFormDataProduct] = useState({ ...initialFormDataProduct });

  const setDateOfBirthls = (date) => {
    setFormDataProduct(prevData => ({
      ...prevData,
      date: date,
    }));
  };
  const setLabells = (value, field) => {
    setFormDataProduct({
      ...formDataProduct,
      [field]: value // Update the specified field in formData with the provided value
    });
  };
  const [stockDataCombiand, setstockDataCombiand] = useState([]);
  const combiandStock = async () => {

  }
  const [ProductDataupdate, setProductData] = useState([]);
  const [userDataProduct, setUserDataProduct] = useState([]);
  const [stockDataProductSales, setstockDataProductSales] = useState([]);
  const [stockDataProductPurchase, setstockDataProductPurchase] = useState([]);
  
  const mergedData = {
    sales: stockDataProductSales.map(sale => ({ type: "outward", ...sale })),
    purchase: stockDataProductPurchase.map(purchase => ({ type: "inward", ...purchase }))
  };


  const fetchDataProduct = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/product/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      setLoading(false);
    
      if (responseData.status === 200) {
        setUserDataProduct(responseData.data);
        setProductData(responseData.data)

      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  const fetchProductSales = async () => {
    try {
      setLoading(true);
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/saleinvoice/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });
      setLoading(false)
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging
     
      if (responseData) {
        let sortedData = responseData.data;

      
        if (formDataProduct.product_id) {
        
          sortedData = sortedData.filter(user => user.product_id === formDataProduct.product_id);
        }

        if (formDataProduct.fromdate && formDataProduct.todate) {
          // Convert string dates to Date objects
          const fromDate = new Date(formDataProduct.fromdate);
          const toDate = new Date(formDataProduct.todate);

          // Filter data within the date range
          sortedData = sortedData.filter(user => {
            const createDate = new Date(user.bill_date);
            return createDate >= new Date(fromDate) && createDate <= new Date(toDate);
          });
        }

        setstockDataProductSales(sortedData);

      } else {
        throw new Error(responseData.message ? responseData.message : 'Unknown error');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setLoading(false)
    }
  };
  const fetchProductPurchase = async () => {
    try {
      setLoading(true);
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/purchasebill/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });
      setLoading(false)
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging
     
      if (responseData) {
        let sortedData = responseData.data;

        
        if (formDataProduct.product_id) {
         
          sortedData = sortedData.filter(user => user.product_id === formDataProduct.product_id);
        }

        if (formDataProduct.fromdate && formDataProduct.todate) {
          // Convert string dates to Date objects
          const fromDate = new Date(formDataPurchaseParty.fromdate);
          const toDate = new Date(formDataPurchaseParty.todate);

          // Filter data within the date range
          sortedData = sortedData.filter(user => {
            const createDate = new Date(user.bill_date);
            return createDate >= fromDate && createDate <= toDate;
          });
        } else if (formDataProduct.fromdate) {
          // Filter data from the fromDate onwards
          const fromDate = new Date(formDataProduct.fromdate);
          sortedData = sortedData.filter(user => {
            const createDate = new Date(user.bill_date);
            return createDate >= fromDate;
          });
        } else if (formDataProduct.todate) {
          // Filter data up to the toDate
          const toDate = new Date(formDataPurchaseParty.todate);
          sortedData = sortedData.filter(user => {
            const createDate = new Date(user.bill_date);
            return createDate <= toDate;
          });
        }

        setstockDataProductPurchase(sortedData);

      } else {
        throw new Error(responseData.message ? responseData.message : 'Unknown error');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setLoading(false)
    }
  };
  useEffect(() => {
    fetchDataProduct();
    fetchProductSales();
    fetchProductPurchase();
    combiandStock();

  }, [stockDataProductSales, stockDataProductPurchase]);


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const renderContent = () => {
    switch (activeTab) {
      case 'Stock':
        return <div className='all_report_table main my-2'>

          <Row>
            <Col lg='12'>
              <div className=''>Stock Report</div>
            </Col>
            <Col lg='12'>
              <div className='text-end mx-2'>
                <PDFDownloadLink document={<StockPDF data={ProductDataupdate} />} fileName="StockReport.pdf">
                  {({ blob, url, loading, error }) => (
                    <a className="dropdown-item" role="button" tabIndex="0" href={url} download="StockReport.pdf">
                      <Buttons lable={<>Download Pdf</>} />
                    </a>
                  )}
                </PDFDownloadLink>
              </div>
            </Col>
            {/* <Col lg='3'>
              <div>
                <DropDownUI
                  optionlist={userDataProduct.map((item) => ({
                    label: item.product_name,
                    value: item.product_id
                  }))}
                  placeholder='Product'
                  labelname='Product'
                  name='product_id'
                  value={formDataProduct.product_id}
                  onChange={(updatedFormData) => setFormDataProduct({ ...formDataProduct, product_id: updatedFormData.product_id })}
                />
              </div>
            </Col>
            {formDataProduct.product_id !== '' ? (
              <Col lg='3'>
                <div>
                  <DropDownUI
                    optionlist={DropList}
                    placeholder='InOutType'
                    labelname='InOutType'
                    name='inout'
                    value={formDataProduct.inout}
                    onChange={(updatedFormData) => setFormDataProduct({ ...formDataProduct, inout: updatedFormData.inout })}
                  />
                </div>
              </Col>
            ) : ("")}

            <Col lg='3'>
              <div>
                <Calender setLabel={(date) => setLabells(date, 'fromdate')} selectedDate={formDataProduct.data} onSelectDate={setDateOfBirthls} calenderlabel="From Date" />
              </div>
            </Col>
            <Col lg='3'>
              <div>
                <Calender setLabel={(date) => setLabells(date, 'todate')} selectedDate={formDataProduct.data} onSelectDate={setDateOfBirthls} calenderlabel="To Date" />
              </div>
            </Col> */}
            {formDataProduct.product_id !== '' ? (
              <Col lg='12' className=''>
                <div className='wire-table'>
                  <Table>
                    <thead className=''>
                      <tr>
                        <th className='wrdbrk'>S No</th>
                        <th className='wrdbrk'>Date</th>
                        <th className='wrdbrk'>Receipt No</th>
                        <th className='wrdbrk'>Type</th>
                        <th className='wrdbrk'>Product</th>
                        <th className='wrdbrk'>Stock</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stockDataProductSales.reduce((acc, item) => {
                        acc.push({
                          bill_date: item.bill_date,
                          bill_no: item.bill_no,
                          party_id: item.product_name,
                          product_name: item.product.product_name,
                          total: item.total,
                        });

                        return acc;

                      }, []).reverse().map((customer, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{customer.bill_date}</td>
                          <td>{customer.bill_no}</td>
                          <td>{customer.product_name}</td>
                          <td>{customer.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            ) : (
              <Col lg='12' className=''>
                <div className='wire-table'>
                  <Table>
                    <thead className=''>
                      <tr>
                        <th className='wrdbrk'>S No</th>
                        <th className='wrdbrk'>Product</th>
                        <th className='wrdbrk'>Stock</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ProductDataupdate.reduce((acc, item) => {
                        acc.push({
                          product_name: item.product_name,
                          stock: item.crt_stock,
                        });

                        return acc;
                      }, []).reverse().map((product, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{product.product_name}</td>
                          <td>{product.stock}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            )}
          </Row>

        </div>;
      case 'sales':
      //  const fromDate = moment(formData.fromdate, 'DD-MM-YYYY');
        return <div className='all_report_table main my-2'>
          <Row>
            <Col lg='12'>
              <div className='text-end mx-2'>
                {/* <Buttons lable={<>PDF</>} /> */}
                <PDFDownloadLink document={<SaleRep data={stockData} list={salesInvoiceList} fromdate ={formData.fromdate} todate={formData.todate} partyid={formData.party_id} />} fileName="SaleReport.pdf">
                  {({ blob, url, loading, error }) => (
                    <a className="dropdown-item" role="button" tabIndex="0" href={url} download="SaleReport.pdf">
                      <Buttons lable={<>Download Pdf</>} />
                    </a>
                  )}
                </PDFDownloadLink>
              </div>
            </Col>
            <Col lg='3'>
              <div>
                <DropDownUI
                  optionlist={userDataSalesParty.map((item) => ({
                    label: item.party_name,
                    value: item.party_id
                  }))}
                  placeholder='SalesParty'
                  labelname='SalesParty'
                  name='party_id'
                  value={formData.party_id}
                  onChange={(updatedFormData) => setFormData({ ...formData, party_id: updatedFormData.party_id })}
                />
              </div>
            </Col>
            <Col lg='3'>
              <div>
                <Calender setLabel={(date) => setLabel(date, 'fromdate')} selectedDate={formData.fromdate} onSelectDate={setDateOfBirth} calenderlabel="From Date" />
              </div>
            </Col>
            <Col lg='3'>
              <div>
                <Calender setLabel={(date) => setLabel(date, 'todate')} selectedDate={formData.todate} onSelectDate={setDateOfBirth} calenderlabel="To Date" />
              </div>
            </Col>
            <Col lg='12' className=''>
              <div className='wire-table'>
                <Table>
                  <thead className=''>
                    <tr>
                      <th className='wrdbrk'>S No</th>
                      <th className='wrdbrk'>Date</th>
                      <th className='wrdbrk'>Receipt No</th>

                      <th className='wrdbrk'>Party Name</th>
                      <th className='wrdbrk'>SubTotal</th>
                      <th className='wrdbrk'>TaxAmount</th>
                      <th className='wrdbrk'>GrandTotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockData.reduce((acc, item) => {

                      acc.push({
                        bill_date: item.bill_date,
                        bill_no: item.bill_no,
                        party_id: item.party_name,
                        party_name: item.party_details.party_name,
                        total: item.total,
                        sum_total: item.sum_total,
                        gst:item.total-item.sum_total,
                      });

                      return acc;
                    }, []).reverse().map((customer, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{customer.bill_no}</td>
                        <td>{customer.bill_date}</td>
                        <td>{customer.party_name}</td>
                        <td>{customer.sum_total}</td>
                        <td>{customer.gst.toFixed(2)}</td>
                        <td>{customer.total}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

        </div >;
      case 'purchase':
        return <div className='all_report_table main my-2'>
          <Row>
            <Col lg='12'>
              <div className='text-end mx-2'>
                {/* <Buttons lable={<>PDF</>} /> */}
                <PDFDownloadLink document={<PurchaseReport data={stockDataPurchase} fromdate={formDataPurchaseParty.fromdate} todate={formDataPurchaseParty.todate}/>} fileName="PurchaseReport.pdf">
                  {({ blob, url, loading, error }) => (
                    <a className="dropdown-item" role="button" tabIndex="0" href={url} download="PurchaseReport.pdf">
                      <Buttons lable={<>Download Pdf</>} />
                    </a>
                  )}
                </PDFDownloadLink>
              </div>
            </Col>
            <Col lg='3'>
              <div>
                <DropDownUI
                  optionlist={userDataPurchaseParty.map((item) => ({
                    label: item.party_name,
                    value: item.party_id
                  }))}
                  placeholder='PurchaseParty'
                  labelname='PurchaseParty'
                  name='party_id'
                  value={formDataPurchaseParty.party_id}
                  onChange={(updatedFormData) => setFormDataPurchaseParty({ ...formDataPurchaseParty, party_id: updatedFormData.party_id })}
                />
              </div>
            </Col>
            <Col lg='3'>
              <div>
                <Calender setLabel={(date) => setLabell(date, 'fromdate')} selectedDate={formDataPurchaseParty.data} onSelectDate={setDateOfBirths} calenderlabel="From Date" />
              </div>
            </Col>
            <Col lg='3'>
              <div>
                <Calender setLabel={(date) => setLabell(date, 'todate')} selectedDate={formDataPurchaseParty.data} onSelectDate={setDateOfBirths} calenderlabel="To Date" />
              </div>
            </Col>
            <Col lg='12' className=''>
              <div className='wire-table'>
                <Table>
                  <thead className=''>
                    <tr>
                      <th className='wrdbrk'>S No</th>
                      <th className='wrdbrk'>Date</th>
                      <th className='wrdbrk'>Receipt No</th>

                      <th className='wrdbrk'>Party Name</th>
                      <th className='wrdbrk'>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockDataPurchase.reduce((acc, item) => {

                      acc.push({
                        bill_date: item.bill_date,
                        bill_no: item.bill_no,
                        party_id: item.party_name,
                        party_name: item.party_details.party_name,
                        total: item.total,
                      });

                      return acc;
                    }, []).reverse().map((customer, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{customer.bill_date}</td>
                        <td>{customer.bill_no}</td>
                        <td>{customer.party_name}</td>
                        <td>{customer.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

        </div>;
      default:
        return null;
    }
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg='12'>
            <div className='main  my-2 stick-top'>
              <div className='report-title  py-3'>Transaction Reports</div>
              <div className='report'>
                <Nav className="py-3">
                  <li>
                    <Nav.Link onClick={() => handleTabClick('Stock')}
                      className={activeTab === 'Stock' ? 'navlink active' : 'navlink'}>Stock</Nav.Link>
                  </li>
                  <li>
                    <Nav.Link onClick={() => handleTabClick('sales')} className={activeTab === 'sales' ? 'navlink active' : 'navlink'}>Sales</Nav.Link>
                  </li>
                  <li>
                    <Nav.Link onClick={() => handleTabClick('purchase')} className={activeTab === 'purchase' ? 'navlink active' : 'navlink'}>Purchase</Nav.Link>
                  </li>
                </Nav>
              </div>
            </div>
          </Col>
          <Col lg='12' className='py-2'>
            <div>
              <div>{renderContent()}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Reports