import React, { useState, useEffect } from 'react'
import { Container, Nav, Navbar, Modal, Col, Row, Table, Offcanvas } from 'react-bootstrap'
import { Buttons, ClickButton } from '../components/Buttons'
import { TableUI } from '../components/Tables'
import { PageHead, PageTitle } from '../components/PageTitle'
import { Calender, DropDownUI, TextInputForm } from '../components/Forms'
import { MdOutlineClose } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Navigate } from 'react-router-dom';

import moment from 'moment/moment'
import PayInPdf from "./PayInPdf";
const ProductHistory = ["S No", "Date", "Receipt No.", "Name", "Amount", "Status"]



const PayIn = () => {


    const navigate = useNavigate();
    //   const [cookies, setCookie] = useCookies(['company_id']);
    const [openFilter, setFilter] = useState(false);
    const closeFilter = () => setFilter(false);
    const showFilter = () => setFilter(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setCrtPayIn(null)
        setFormData({
            party_id: '',
            receipt_date: '',
            paid: '',
        })
        setShow(true);
    }
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        })
    })
    const [crtPayIn, setCrtPayIn] = useState(null);
    const handleEditClick = (rowdata) => {
        setCrtPayIn(rowdata)
        setShow(true)

    };
    const [formData, setFormData] = useState({
        party_id: '',
        receipt_date: '',
        paid: '',
    })
    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;
        if (crtPayIn != null) {
            setCrtPayIn({
                ...crtPayIn,
                [fieldName]: value
            });
        } else {
            setFormData({
                ...formData,
                [fieldName]: value
            });
        }
    };
    const setLabel = (value, field) => {
        setFormData({
            ...formData,
            [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
        });
    };
    const [filterData, setFilterData] = useState({
        search_text: '',
        party_id: '',
        from_date: '',
        to_date: ''
    })
    console.log('kanna', filterData);
    const handleChanges = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;
        setFilterData({
            ...filterData,
            [fieldName]: value
        });
    };
    const setLabell = (value, field) => {
        setFilterData({
            ...filterData,
            [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
        });
    };
    const [crtSalesParty, setSalesParty] = useState([]);
    const fetchDataSalesparty = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://node.zentexus.com/salesparty/list/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: ''
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            console.log(responseData)
            setLoading(false);
            if (responseData.status === 200) {
                setSalesParty(responseData.data);
                console.log(setSalesParty)
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };
    useEffect(() => {
        fetchDataSalesparty(); // Call fetchData directly in useEffect
    }, []);
    const [userData, setUserData] = useState([]);
    console.log('userData', userData);
    console.log('data', userData);
    const fetchData = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://node.zentexus.com/payin/list/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: filterData.search_text,
                    party_id: filterData.party_id,
                    from_date: filterData.from_date,
                    to_date: filterData.to_date
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            console.log(responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setUserData(responseData.data);
                console.log(responseData)

            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };
    useEffect(() => {
        fetchData(); // Call fetchData directly in useEffect

    }, [filterData]);
    const clearFilter = () => {
        setFilterData({
            search_text: '',
            party_id: '',
            from_date: '',
            to_date: ''
        })
    }
    const errorAlert = (input) => {
        toast.error(input, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    const successAlert = (success) => {
        toast.success(success, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",

        });
    }
    const [showAlert, setShowAlert] = useState(false);
    const [showPdf, setShowPdf] = useState(false);
    const handleSubmit = async () => {
        try {

            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://node.zentexus.com/payin/create/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            console.log(formData);
            const responseData = await response.json();
            console.log(responseData);
            if (responseData.status === 200) {
                fetchData();
                setFormData({
                    party_id: '',
                    receipt_date: '',
                    paid: '',
                })
                successAlert(responseData.msg)
                setTimeout(() => {
                    handleClose(); // Close modal after 10 seconds
                }, 2000);
                // navigate("/console/payinpreview", { state: { type: "print"} });
                //setShowPdf(true);

            }
            else if (responseData.status === 400) {
                toast.error('Missing required fields!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else {
                setShowAlert(true);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleUpdateSubmit = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://node.zentexus.com/payin/update/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ // Include the company ID in the request
                    payin_id: crtPayIn.payin_id,
                    paid: crtPayIn.paid,
                    receipt_date: crtPayIn.receipt_date,
                    party_id: crtPayIn.party_id,

                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update company');
            }

            const responseData = await response.json();
            console.log(responseData);

            if (responseData.status === 200) {
                fetchData();
                toast.success('PayIn updated successfully!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    handleClose();
                }, 2000);

                // Navigate to the user list page after a delay

            } else {
                console.error(responseData.msg || 'Unknown error occurred during update');
            }
        } catch (error) {
            console.error('Error updating product:', error.msg);
        }

        setLoading(false);
    };
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    useEffect(() => {
        const checkSession = async () => {
            try {
                // Check session logic here, e.g., fetch from local storage or server
                const session = localStorage.getItem('session');
                if (!session) {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Error checking session:', error.message);
                setIsLoggedIn(false);
            }
        };
        checkSession();
    }, []);
    if (!isLoggedIn) {

        return <Navigate to="/login" replace />;
    }
    return (
        <div >
            <Container fluid>

                <div>
                    <div className='main p-3 my-2'>

                        <div>
                            <Navbar >
                                <Navbar.Brand><PageTitle PageTitle={"Pay In"} /></Navbar.Brand>
                                <Nav className="ms-auto">
                                    {/* <span className='mx-2 py-2'> <Buttons lable={<>PDF</>} /></span> */}
                                    <span className='mx-2 py-2'><Buttons lable={<>Filter</>} onClick={showFilter} /></span>
                                </Nav>
                            </Navbar>
                        </div>
                        <Offcanvas show={openFilter} onHide={closeFilter} placement='end'>
                            <Offcanvas.Body>
                                <Row>
                                    <Col lg="6">
                                        <PageTitle PageTitle={<>Purchase Bill</>} />
                                    </Col>
                                    <Col lg="6" className='align-self-center'>
                                        <div className='text-end'>
                                            <Buttons onClick={closeFilter} lable={<><MdOutlineClose /></>}></Buttons>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <div className='py-3'>

                                        <TextInputForm
                                            labelname={'Search Receipt'}
                                            placeholder={'Search Receipt'}
                                            name='search_text'
                                            value={filterData.search_text}
                                            onChange={(e) => handleChanges(e, 'search_text')}
                                        ></TextInputForm>
                                    </div>
                                    <div className='py-3'>

                                        <DropDownUI
                                            optionlist={crtSalesParty.map(user => ({
                                                value: user.party_id,
                                                label: user.party_name
                                            }))}
                                            placeholder='PartyName'
                                            labelname='PartyName'
                                            name='party_id'
                                            value={filterData.party_id}
                                            onChange={(updatedFormData) => setFilterData({ ...filterData, party_id: updatedFormData.party_id })}
                                        />
                                    </div>
                                    <Row>
                                        <Col lg="6" className='align-self-center'>
                                            <div>
                                                <Calender setLabel={(date) => setLabell(date, 'from_date')} selectedDate={filterData.from_date} calenderlabel="From Date" />
                                            </div>
                                        </Col>
                                        <Col lg="6" className='align-self-center'>
                                            <div>
                                                <Calender setLabel={(date) => setLabell(date, 'to_date')} selectedDate={filterData.to_date} calenderlabel="To Date" />
                                            </div>
                                        </Col>

                                        <Col lg="12" className='align-self-center'>
                                            <div className='my-5 text-center'>
                                                <Buttons lable={"Clear"} onClick={clearFilter}></Buttons>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                    <div className='main p-3'>

                        <div className='py-1'>
                            <Navbar >

                                <Navbar.Brand><PageTitle PageTitle={"Transactions"} /></Navbar.Brand>
                                <Nav className="ms-auto">
                                    <span className='mx-2 py-2'> <Buttons lable={<>Add New</>} onClick={handleShow} /></span>
                                </Nav>

                            </Navbar>
                        </div>
                        <div className='py-2 table-scroll'>
                            <TableUI headers={ProductHistory} body={userData} type='salespayin' pageview={"yes"} handleEditClick={handleEditClick} onDelete={fetchData} />
                        </div>

                    </div>
                    <>

                        <Modal show={show} onHide={handleClose} size='xl' centered scrollable>
                            <Modal.Header>
                                <Modal.Title>
                                    <PageHead pagehead={<>Pay In</>} onClick={handleClose} />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container fluid>
                                    <Table>
                                        <tr>
                                            <td className='w-50'>
                                                <div>
                                                    <DropDownUI
                                                        optionlist={crtSalesParty.map(user => ({
                                                            value: user.party_id,
                                                            label: user.party_name
                                                        }))}
                                                        placeholder='Select Party'
                                                        labelname='Select Party'
                                                        name='party_id'
                                                        value={crtPayIn != null ? crtPayIn.party_id : formData.party_id}
                                                        onChange={(updatedFormData) => {
                                                            if (crtPayIn != null) {
                                                                setCrtPayIn({ ...crtPayIn, party_id: updatedFormData.party_id })
                                                            } else {
                                                                setFormData({ ...formData, party_id: updatedFormData.party_id })
                                                            }
                                                        }

                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td className='float-end'>
                                                <div>
                                                    <div>
                                                        <Calender setLabel={(date) => setLabel(date, 'receipt_date')} selectedDate={crtPayIn != null ? crtPayIn.receipt_date : formData.receipt_date} calenderlabel="Date" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {/* <div><PayMents /></div> */}
                                            </td>
                                            <td className=''>
                                                <div className='py-3 w-75 ms-auto' >
                                                    <TextInputForm
                                                        labelname={'Paid'}
                                                        placeholder={'Paid'}
                                                        name='paid'
                                                        value={crtPayIn != null ? crtPayIn.paid : formData.paid}
                                                        onChange={(e) => handleChange(e, 'paid')}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </Table>
                                    <Row>
                                        <Col lg="6">

                                        </Col>
                                        <Col lg="6">
                                            <div>

                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className='text-center'>
                                    {crtPayIn != null ? (
                                        <>
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={3000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="light"
                                            />
                                            <span className='mx-2'>
                                                <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                                            </span>

                                            <span className='mx-2'>
                                                <ClickButton label={<>Cancel</>} onClick={handleClose}></ClickButton>
                                            </span>
                                        </>

                                    ) : (
                                        <>
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={3000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="light"
                                            />
                                            <span className='mx-2'>
                                                <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
                                            </span>

                                            <span className='mx-2'>
                                                <ClickButton label={<>Cancel</>} onClick={() => { handleClose() }}></ClickButton>
                                            </span>
                                        </>
                                    )}
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </>
                </div>
            </Container>
            {/* {showPdf && <PayInPdf type="print" />} */}
        </div>
    )
}

export default PayIn