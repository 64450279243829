import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal, Offcanvas } from 'react-bootstrap';
// import {useLocation} from 'react-router-dom';
import { Buttons, ClickButton } from '../components/Buttons';
import { TableUI } from '../components/Tables'
import { PageHead, PageTitle } from '../components/PageTitle';
import { MdOutlineClose } from "react-icons/md";
import { Calender, DropDown, DropDownUI, TextInputForm } from '../components/Forms';
import { useLocation, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const UnitTablehead = ["Unit Name", 'Action']
const ProductUnitTabelhead = ["S.No", "conversion", "status"]
const UnitList = () => {
  const location = useLocation();
  const { } = location.state || {};
  const [open, OpenShow] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false)
    location.state = [];
  };
  const handleShow = () => {
    setUnit(null)
    setFormData({
      unit_name: '',
      short_name: '',
      subunit_name: ''
    })
    setShow(true);
  }
  const [showConversion, setShowConversion] = useState(false);

  const handleCloseConversion = () => setShowConversion(false);
  const handleShowConversion = () => setShowConversion(true);
  const Close = () => OpenShow(false);
  const Show = () => OpenShow(true);
  ;

  const [clickedData, setClickedData] = useState(null);
  const handleClick = (data) => {
    setClickedData(data);
  };
  const [crtUnit, setUnit] = useState(null);

  // Function to open modal
  const handleEditClick = (rowdata) => {
    setUnit(rowdata)
    setShow(true)

  };
  const [formData, setFormData] = useState({
    unit_name: '',
    short_name: '',
    subunit_name: ''
  });
  const [convert, setConvert] = useState({
    unit_id: '',
    rate: '',
    subunit_id: ''
  })
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtUnit != null) {
      setUnit({
        ...crtUnit,
        [fieldName]: value
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: value
      });
    }
  };

  const handleUnitChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtUnit != null) {
      setUnit({
        ...crtUnit,
        [fieldName]: value
      });
    } else {
      setConvert({
        ...convert,
        [fieldName]: value
      });
    }
  };
  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/unit/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(setUserData)
        if (responseData.data.length > 0) {
          setClickedData(responseData.data[0])
        }
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, []);
  const [showAlert, setShowAlert] = useState(false);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",

    });
  }
  const handleSubmit = async () => {
    try {

      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/unit/create/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      console.log(formData);
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.status === 200) {
        setFormData({
          unit_name: '',
          short_name: ''
        })
        fetchData();
        successAlert(responseData.msg)
        setTimeout(() => {
          handleClose(); // Close modal after 10 seconds
        }, 2000);
      }
      else if (responseData.status === 400) {
        toast.error('Missing required fields!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      else {
        setShowAlert(true);
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleConvert = async () => {
    try {
      if (convert.unit_id === '' || convert.rate === '' || convert.subunit_id === '') {
        if (convert.unit_id === '') {
          errorAlert("Unit is Must");
        } else if (convert.rate === '') {
          errorAlert("Rate is Must");
        }
        else if (convert.subunit_id === '') {
          errorAlert("SubUnit is Must");
        }
      } else {
        const response = await fetch('https://node.zentexus.com/unitconversion/create/joyg67j8t689t', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(convert)
        });
        console.log(convert);
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.status === 200) {
          setConvert({
            unit_id: '',
            rate: '',
            subunit_id: '',
          })
          fetchData();
          successAlert(responseData.msg)
          setTimeout(() => {
            handleCloseConversion(); // Close modal after 10 seconds
          }, 2000);
        }
        else if (responseData.status === 400) {
          toast.error('Missing required fields!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://node.zentexus.com/unit/update/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ // Include the company ID in the request
          unit_id: crtUnit.unit_id,
          unit_name: crtUnit.unit_name,
          short_name: crtUnit.short_name
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update company');
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        fetchData();
        toast.success('User updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          handleClose();
        }, 2000);

        // Navigate to the user list page after a delay

      } else {
        console.error(responseData.msg || 'Unknown error occurred during update');
      }
    } catch (error) {
      console.error('Error updating product:', error.msg);
    }

    setLoading(false);
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <Container fluid>
        <Row className='justify-content-center'>
          <Col lg="8" className='py-1'>
            <div className='main my-2'>
              <div className='fixed'>
                <div></div>
                <div className='text-end py-2'><Buttons lable={<> Add New</>} onClick={handleShow} /></div>
              </div>
              <div className='py-2 c-table'><TableUI headers={UnitTablehead} body={userData} type="itemsunit" onDelete={fetchData} handleEditClick={handleEditClick} onClick={handleClick}></TableUI></div>
            </div>
          </Col>
          {/* <Col lg='9'>
            <div className='my-2'>
              <div className='main'>
                {clickedData &&
                  <div className='d-flex justify-content-between'>
                    <div>
                      <div className='unit-name py-2'>{clickedData.unit_name}</div>
                    </div>
                    <div className='text-end'>
                      <div className='product-name py-2'><Buttons lable={<>Add Conversion</>} onClick={handleShowConversion} /></div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className='my-2'>
              <div className='main'>
                <Row>
                  <Col lg="4" className='align-self-center'>
                    <PageTitle PageTitle={<>Category</>} />
                  </Col>
                  <Col lg="3" className='align-self-center ms-auto'>
                    <div>
                      <DropDownUI labelname={<>Select Unit</>} />
                    </div>
                  </Col>
                </Row>

                <div className='py-2 c-table'>
                  <TableUI headers={ProductUnitTabelhead} body={clickedData != null ? clickedData.transactions : []} type='itemsunitransactions'></TableUI></div>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header>
            <PageHead pagehead={<> Add Unit</>} onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg="6" className='py-3'>
                  <TextInputForm
                    labelname={'Unit'}
                    placeholder={'Unit'}
                    name='unit_name'
                    value={crtUnit != null ? crtUnit.unit_name : formData.unit_name}
                    onChange={(e) => handleChange(e, 'unit_name')}
                  ></TextInputForm>
                </Col>
                <Col lg="6" className='py-3'>
                  <TextInputForm
                    labelname={'Short Name'}
                    placeholder={'Short Name'}
                    name='short_name'
                    value={crtUnit != null ? crtUnit.short_name : formData.short_name}
                    onChange={(e) => handleChange(e, 'short_name')}
                  ></TextInputForm>
                </Col>
              </Row>
              <div className='text-center py-4'>
                {crtUnit != null ? (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                    <span className='mx-2'>
                      <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>
                    </span>
                    <span className='mx-2'>
                      <ClickButton label={<>Cancel</>} onClick={() => handleClose()}></ClickButton>
                    </span>
                  </>

                ) : (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                    <span className='mx-2'>
                      <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
                    </span>

                    <span className='mx-2'>
                      <ClickButton label={<>Cancel</>} onClick={() => handleClose()}></ClickButton>
                    </span>
                  </>
                )}
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </>
      <>
        <Modal
          show={showConversion}
          onHide={handleCloseConversion}
          centered
          size="lg"
        >
          <Modal.Header>
            <PageHead pagehead={<> Add Conversion</>} onClick={handleCloseConversion} />
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg="5" className='py-3'>
                  <DropDownUI
                    optionlist={userData.map(user => ({
                      value: user.unit_id,
                      label: user.unit_name
                    }))}
                    placeholder='Unit Name'
                    labelname='Unit Name'
                    name='unit_id'
                    value={crtUnit != null ? crtUnit.unit_id : convert.unit_name}
                    onChange={(updatedFormData) => setConvert({ ...convert, unit_id: updatedFormData.unit_id })}
                  />
                </Col>
                <Col lg="2" className='py-3'>
                  <TextInputForm labelname={'Rate'}
                    placeholder={'Rate'}
                    name='rate'
                    value={crtUnit != null ? crtUnit.short_name : convert.rate}
                    onChange={(e) => handleUnitChange(e, 'rate')} />
                </Col>
                <Col lg="5" className='py-3'>
                  <DropDownUI
                    optionlist={userData.map(user => ({
                      value: user.unit_id,
                      label: user.unit_name
                    }))}
                    placeholder='SubUnit Name'
                    labelname='SubUnit Name'
                    name='subunit_id'
                    value={crtUnit != null ? crtUnit.subunit_name : convert.subunit_id}
                    onChange={(updatedFormData) => setConvert({ ...convert, subunit_id: updatedFormData.subunit_id })}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Buttons lable={<>Close</>} onClick={handleCloseConversion} />
            <Buttons lable={<>Save & New</>} onClick={handleConvert} />
          </Modal.Footer>
        </Modal>
      </>
    </div >
  )
}

export default UnitList